import React from 'react'


import aranthai from '../images/mentors/Aranthai_maniyan.jpg'
import stanley from '../images/mentors/ss_stanly.jpg'
import cjraj from '../images/mentors/CJ_rajkumar.jpg'
import ramesh from '../images/mentors/Rameshkumar.jpg'
import madhu from '../images/mentors/Madhu_ambat.jpg'
import manoj from '../images/mentors/Manoj_Paramahamsa.jpg'
import prasanna from '../images/mentors/Prasanna.jpg'
import raja from '../images/mentors/Raja_mohhamed.jpg'
import arvind from '../images/mentors/Aravind.jpg'
import muniraj from '../images/mentors/muniraj.jpg'
import srini from '../images/mentors/Srinivas.jpg'
import sara from '../images/mentors/saravanan.jpg'
import { Link } from 'react-router-dom'


const Mentors = () => {

    function topPage() {
        window.scroll(0, 0)
    }

    return (
        <div>
            <div className='flex justify-center items-center'>

                <div className='grid grid-cols-2 md:grid-cols-4 gap-y-8'>

                    {/* manoj */}

                    <div>
                        <div>
                            <Link to='manoj_paramahamsa' onClick={topPage}>
                                <div className='flex justify-center items-center'>
                                    <img src={manoj} className='w-[88%] object-cover rounded-xl border-b border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>

                        </div>
                    </div>


                    {/* madhu */}

                    <div>
                        <div>
                            <Link to='madhu_ambat' onClick={topPage}>
                                <div className='flex justify-center items-center'>
                                    <img src={madhu} className='w-[88%] object-cover rounded-xl border-b border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>

                        </div>
                    </div>


                    {/* A. aranthi */}

                    <div>
                        <div>
                            <Link to='contact'>
                                <div className='flex justify-center items-center'>
                                    <img src={aranthai} className='w-[88%] object-cover rounded-xl border border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>



                            {/* <div className='flex justify-center mt-5 border border-gray-800/45   px-6 py-6 h-[23rem] my-auto rounded-md w-[88%]mx-auto'>
<ul className='flex flex-col gap-y-3 list-disc text-white font-[roboto] text-[14px] '>
<li> Visionary cinematographer behind the trend-setting visuals of 'Oomai Vizhigal', a film that revolutionized Tamil cinema.</li>
<li> Instrumental in bringing the cinemascope format into popular filmmaking.  </li>
<li> Pioneered the integration of high-quality film education into leading film institutes. </li>
<li> Set new trends in advertising with over 800 filmed commercials </li>
<li> Renowned for his innovative and influential contributions to both cinema and advertising. </li>

</ul>
</div> */}

                        </div>
                    </div>

                    {/* ss stanley */}

                    <div>
                        <div>
                            <Link to='stanley' onClick={topPage}>
                                <div className='flex justify-center items-center'>
                                    <img src={stanley} className='w-[88%] object-cover rounded-xl border-b border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>

                        </div>
                    </div>


                    {/* CJ Raj Kumar */}
                    <div>
                        <div>
                            <Link to='contact'>
                                <div className='flex justify-center items-center'>
                                    <img src={cjraj} className='w-[88%] object-cover rounded-xl border-b border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>

                        </div>
                    </div>


                    {/* A. Ramesh Kumar */}

                    <div>
                        <div>
                            <Link to='contact'>
                                <div className='flex justify-center items-center'>
                                    <img src={ramesh} className='w-[88%] object-cover rounded-xl border-b border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>

                        </div>
                    </div>

                    {/* srivani */}

                    <div>
                        <div>
                            <Link to='contact'>
                                <div className='flex justify-center items-center'>
                                    <img src={srini} className='w-[88%] object-cover rounded-xl border border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>
                        </div>
                    </div>



                    {/* prasanna */}

                    <div>
                        <div>
                            <Link to='contact'>
                                <div className='flex justify-center items-center'>
                                    <img src={prasanna} className='w-[88%] object-cover rounded-xl border border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>

                        </div>
                    </div>


                    {/* Raja */}

                    <div>
                        <div>
                            <Link to='contact'>
                                <div className='flex justify-center items-center'>
                                    <img src={raja} className='w-[88%] object-cover rounded-xl border border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>

                        </div>
                    </div>

                    {/* Arvind */}

                    <div>
                        <div>
                            <Link to='aravind' onClick={topPage}>
                                <div className='flex justify-center items-center'>
                                    <img src={arvind} className='w-[88%] object-cover rounded-xl border border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>

                        </div>
                    </div>


                    {/* muniraj */}

                    <div>
                        <div>
                            <Link to='contact'>
                                <div className='flex justify-center items-center'>
                                    <img src={muniraj} className='w-[88%] object-cover rounded-xl border border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>
                        </div>
                    </div>





                    {/* saravanan */}

                    <div>
                        <div>
                            <Link to='contact'>
                                <div className='flex justify-center items-center'>
                                    <img src={sara} className='w-[88%] object-cover rounded-xl border border-gray-400 hover:scale-95 duration-300' alt="" />
                                </div>
                            </Link>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default Mentors
