import React from 'react'
import Slider from "react-slick";
import DirectionFAQ from '../../components/Direction_FAQ';
import '../text.css'

import { FaArrowRight } from "react-icons/fa6";


import banner from '../../images/course/banner/pattern.jpg'
import frame from '../../images/course/banner/direction.jpg'
import stanley from '../../images/mentors/ss_stanly.jpg'
import light from '../../images/course/banner/director.png'
import who from '../../images/course/direction/who.png'

import mentor from '../../images/course/direction/ss_stanly.png'

import orange from '../../images/banner/orange.jpg'

import posterOne from '../../images/poster/mv1.jpg'
import posterTwo from '../../images/poster/mv2.jpg'
import posterThree from '../../images/poster/mv3.png'
import posterFour from '../../images/poster/mv4.png'






//icons
import { RiWhatsappLine } from "react-icons/ri";

import { TbCreativeCommonsBy } from "react-icons/tb";
import { PiFilmSlateDuotone } from "react-icons/pi";
import { MdLeaderboard } from "react-icons/md";
import { MdOutlineSystemSecurityUpdateGood } from "react-icons/md";
import { MdOutlineSyncProblem } from "react-icons/md";
import { RiIndeterminateCircleLine } from "react-icons/ri";

import iconOne from '../../images/course/direction/1.png'
import iconTwo from '../../images/course/direction/19.png'
import iconThree from '../../images/course/direction/Produce.png'
import iconFour from '../../images/course/direction/vfx.png'
import iconFive from '../../images/course/direction/2.png'
import iconSix from '../../images/course/direction/ai.png'
import iconSeven from '../../images/course/direction/staging_characters.png'
import iconEight from '../../images/course/direction/3.png'



const Direction = () => {

    const settings = {
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
        },
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "30px",
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "15px",
                }
            }
        ]
    };



    return (
        <>

            <div className='font-kumbh overflow-hidden '>

                <div className='  '>
                    <img src={banner} className=' blur-[2px] w-full fixed top-0 object-cover h-screen -z-30' alt="" />
                </div>


                <section>
                    <div className=''>

                        <div>
                            <img src={frame} className='w-full object-cover' alt="" />
                        </div>

                    </div>
                </section>


                {/* -------------- course highligts ----------------- */}

                <section className='bg-gray-950'>

                    <div className='relative'>

                        <div className='relative'>
                            <img src={light} className='w-full object-cover h-screen md:h-auto' alt="" />
                            <div className='w-full backdrop-blur-sm bg-[#ffff00]/15 inset-0 absolute top-0'></div>
                        </div>

                        <div className='absolute w-full top-0'>

                            <div className=' w-full px-4 md:w-[90%] mx-auto mt-10 md:mt-16'>

                                <div className='mb-8 flex flex-col items-center'>
                                    <h3 className='font-bold text-[20px] md:text-[40px] text-white text-center uppercase tracking-[2px]'>Course highlights </h3>
                                    {/* <div className='border-2  border-orange-500 rounded-[80%] w-[30%] '></div> */}
                                </div>

                                <div className='flex justify-center items-center md:mt-12'>

                                    <div className='grid grid-cols-2 md:grid-cols-4 gap-x-6 md:gap-x-14 gap-y-4 md:gap-y-14  text-[12px] md:text-[14px]'>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full  rounded-md'>

                                                <div>
                                                    <img src={iconOne} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Film complex action scenes</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full  rounded-md'>

                                                <div>
                                                    <img src={iconTwo} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Master the language of cinema</h3>
                                            </div>
                                        </div>


                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full rounded-md'>

                                                <div>
                                                    <img src={iconThree} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Produce captivating music videos</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full rounded-md'>

                                                <div>
                                                    <img src={iconFour} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Use VFX in film direction</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full rounded-md'>

                                                <div>
                                                    <img src={iconFive} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Train in writing various screenplay methods</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full rounded-md'>

                                                <div>
                                                    <img src={iconSix} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Integrate AI in screenplay and direction</h3>
                                            </div>
                                        </div>


                                        <div className='hidden md:flex'>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full rounded-md'>

                                                <div>
                                                    <img src={iconSeven} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Perfect the art of staging characters</h3>
                                            </div>
                                        </div>

                                        <div className='hidden md:flex'>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full rounded-md '>

                                                <div>
                                                    <img src={iconEight} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Learn techniques for filming dialogues</h3>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                                {/* <div class=" p-4">

                                    <div class="flex flex-col grid-cols-9 p-2 mx-auto md:grid">
                                        <div class="flex md:contents flex-row-reverse">

                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Hone skills in editing and other crafts</h3>

                                            </div>

                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 rounded-t-full bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center  ">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>1</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex md:contents">
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>2</h3>
                                                </div>
                                            </div>
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-white drop-shadow-md rounded-xl col-start-6 col-end-10 mr-auto">
                                                <h3 class="text-[20px]  font-semibold  ">Integrate AI in screenplay & direction</h3>

                                            </div>
                                        </div>

                                        <div class="flex md:contents flex-row-reverse">
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-white drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  ">Perfect the art of staging characters</h3>

                                            </div>
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center justify-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300  bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>3</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex md:contents">
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col justify-center items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>4</h3>
                                                </div>
                                            </div>
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-6 col-end-10 mr-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Master the language of cinema</h3>

                                            </div>
                                        </div>


                                        <div class="flex md:contents flex-row-reverse">
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Learn techniques for filming dialogues</h3>
                                            </div>
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center justify-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300  bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>5</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}


                            </div>

                        </div>

                    </div>


                </section>



                {/* -------------- Syllabus ----------------- */}

                <section className='border-t-4 border-orange-500 pt-16 pb-10 md:pt-20 md:pb-20 bg-gray-950 -mt-6'>
                    <div className=' px-4 w-full md:w-[85%] mx-auto'>

                        <div className='flex flex-col gap-y-2 justify-center items-center mb-6 md:mb-16'>
                            <h3 className='font-bold text-center text-[24px] md:text-[40px] text-white font-kumbh uppercase'>1 Year Diploma Course  </h3>
                            <p className='font-semibold text-[18px] md:text-[24px] text-[#ff0000] font-[roboto] uppercase tracking-[1px] '> in Direction</p>
                        </div>

                        <div className='flex flex-col md:flex-row items-center gap-y-8'>

                            <div className='w-full md:w-[50%] md:px-5 mx-auto'>
                                <div>
                                    <div class="border border-gray-600 overflow-y-auto h-[25rem] scrollbar-style-one px-2 md:px-6 py-4 rounded-md overscroll-y-contain	">

                                        <div className=''>
                                            <h2 className=' text-[18px] md:text-[26px] font-semibold mb-6 text-white'>Semester 1: Understanding the Basics</h2>

                                            <div className='flex flex-col gap-y-4 md:gap-y-6'>

                                                <div className='flex flex-col gap-y-3'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Understanding Cinema: What is cinema?</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>The Language of Cinema: Visual storytelling, mise-en-scène, and cinematography.</li>
                                                        <li>Attraction to Cinema: Exploring what draws audiences to the silver screen.</li>
                                                    </ul>
                                                </div>


                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Developing Story Ideas</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>Knowledge of the Story World: Building believable and immersive worlds.</li>
                                                        <li>Developing Thoughts and Ideas: From brainstorming to initial concepts.</li>
                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>   Themes and Story Ideas</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> Identifying Themes: Recognizing central themes in storytelling.</li>
                                                        <li> Developing Story Ideas: Crafting compelling narratives.</li>
                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Grammar of Cinema  </h3>

                                                    <ul className='tet-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> Understanding the Grammar of Cinema:Shots, cuts, and transitions. </li>
                                                        <li>Practical Exercises: Applying cinematic grammar in short exercises.</li>
                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Direction and Production </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> What is Direction: Roles and responsibilities of a director.</li>
                                                        <li>   Directorial Techniques: From pre-production to post-production.</li>
                                                        <li> Understanding Production: How production houses and the film industry operate.</li>
                                                        <li>    The Production Process: From development to distribution.</li>
                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Types of Films, Target Audiences, and Story Structures </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>    Film Genres: Exploring different types of films.</li>
                                                        <li>  Target Audiences: Identifying and understanding your audience.</li>
                                                        <li>   The Seven Stories: Universal story structures.</li>
                                                        <li>Types of Conflicts: Crafting compelling conflicts.</li>
                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Genres, Plots, and Central Themes in Cinema  </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>  Genres of Cinema:Detailed study of various film genres. </li>
                                                        <li>  Plot Development:Creating engaging and coherent plots.</li>
                                                        <li> Top 10 Themes: Exploring recurring themes in cinema. </li>
                                                        <li> Thematic Depth:Adding layers to your narrative. </li>
                                                    </ul>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>


                            <div className='w-full md:w-[50%] md:px-5 mx-auto'>

                                <div>
                                    <div class="border border-gray-600 overflow-y-auto h-[25rem] scrollbar-style-one px-6 py-4 rounded-md overscroll-y-contain	">

                                        <div className='flex flex-col gap-y-6'>

                                            <div className='flex flex-col gap-y-2'>
                                                <h3 className='font-semibold text-[16px] md:text-[26px] text-white'> Semester 2: Web Series Production, AI integration and Advanced Topics  </h3>

                                                <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-2 md:gap-y-3 text-gray-400 mt-4'>
                                                    <li> Web Series Production and Advanced Storytelling </li>
                                                    <li> Web Series Production:Students will write, direct, and produce a web series. </li>
                                                    <li> Hands-on Shooting: Each student takes on various roles in production.</li>
                                                    <li> Crafting Beginnings:Hooking the audience from the start.</li>
                                                    <li> Creating Endings:Leaving a lasting impact.</li>
                                                    <li> Creating Interval Blocks:Structuring your story with intervals.</li>
                                                    <li>Different Climax Sequences:Crafting memorable and powerful climaxes.</li>
                                                    <li> Learning Cinematography:Techniques and tools.</li>
                                                    <li>Editing:The art of storytelling in post-production.</li>
                                                    <li>VFX:Enhancing visuals with special effects.</li>
                                                </ul>
                                            </div>


                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </section>



                {/* ------------------ Mentors ------------------ */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20'>
                    <div className='px-4 w-full md:w-[80%] mx-auto font-kumbh'>

                        <div className='flex items-center justify-center mb-6 md:mb-10'>
                            <h3 className='font-bold text-white text-[20px] md:text-[40px] text-center uppercase md:tracking-[2px]'>Film Maker As Mentor</h3>
                        </div>

                        <div className='flex justify-center items-center'>

                            <div className='grid grid-cols-1 '>

                                <div className='flex flex-col  items-center justify-center'>

                                    <div className='flex justify-center items-center '>
                                        <img src={stanley} className='w-[80%] rounded-md object-cover' alt="" />
                                    </div>

                                    <div className='flex flex-col gap-y-5 items-center justify-center mt-5'>
                                        {/* <div className='flex flex-col gapy-2 items-center '>
                                            <h3 className='font-bold text-[20px] md:text-[28px] text-white md:tracking-[2px]'>SS STANLEY</h3>
                                            <span className='text-orange-500 text-[14px] md:text-[16px]'>Director</span>
                                        </div> */}
                                        <div className=' w-full md:w-[70%] mx-auto '>
                                            <p className=' text-[12px] md:text-[14px] text-gray-200 text-center'>
                                                HOD of our Direction department.  Director SS Stanley, a luminary in filmmaking with decades of experience and international recognition for his acclaimed Tamil films.
                                            </p>
                                        </div>

                                        <div className='flex justify-center items-center font-[poppins]  font-semibold text-[14px] md:text-[16px]'>
                                            <button className="group relative inline-flex h-10 md:h-12 items-center justify-center overflow-hidden rounded-md border border-gray-300 bg-[#ff0000] px-8 md:px-12 font-medium text-neutral-200 duration-500">
                                                <div className="uppercase translate-x-0 opacity-100 transition group-hover:translate-x-[-150%] group-hover:opacity-0 font-semibold font-[poppins] ">
                                                    Explore More
                                                </div>
                                                <div className="absolute translate-x-[150%] opacity-0 transition group-hover:translate-x-0 group-hover:opacity-100">
                                                    <FaArrowRight className='font-semibold text-[18px] text-white' />
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* -------------- Our Mentors have Worked In ------------------------ */}

                <section className='bg-black overflow-hidden flex justify-center items-center pt-8 md:pt-14  pb-6 md:pb-10 '>

                    <div className='w-full mx-auto'>

                        <div className='flex justify-center items-center  mb-8 md:mb-12'>
                            <h3 className='font-bold uppercase text-[20px] md:text-[28px] text-white'>Our Mentor's Work</h3>
                        </div>

                        <div>

                            <div className="slider-container">
                                <Slider {...settings} className="">

                                    <div className='px-2'>
                                        <div>
                                            <img src={posterOne} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>

                                    <div className='px-2'>
                                        <div>
                                            <img src={posterTwo} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>

                                    <div className='px-2'>
                                        <div>
                                            <img src={posterThree} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>

                                    <div className='px-2'>
                                        <div>
                                            <img src={posterFour} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>

                                </Slider>
                            </div>

                        </div>
                    </div>
                </section>


                {/* ----------------------- who can apply -------------------------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-black'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex justify-center items-center mb-8 md:mb-14'>
                            <h3 className='font-bold text-[20px] text-center md:text-[35px] text-white '>Who can apply for the course? </h3>
                        </div>

                        <div className='flex flex-col md:flex-row justify-center items-center gap-y-8' >

                            <div className=' w-full md:w-[40%] mx-auto'>

                                <div className='flex justify-center items-center'>
                                    <img src={who} className='w-full md:w-[90%] object-cover rounded-md shadow-md drop-shadow-lg' alt="" />
                                </div>

                            </div>

                            <div className='w-full md:w-[60%] mx-auto md:px-16'>
                                <div className='flex justify-center items-center w-full'>

                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6 md:gap-y-20 font-semibold w-full'>

                                        <div className='w-full'>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <TbCreativeCommonsBy className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Creative Thinker</h3>
                                            </div>
                                        </div>

                                        <div className='w-full'>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <PiFilmSlateDuotone className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Film Enthusiast</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <MdLeaderboard className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> A leader</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>

                                                    <MdOutlineSystemSecurityUpdateGood className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px] ' > Good Communicator</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <MdOutlineSyncProblem className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'>  Problem Solver</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <RiIndeterminateCircleLine className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Determined Person</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* ------------------------------ FAQ ----------------------- */}

                <section className=' pt-8 md:pt-20 pb-10 md:pb-20 bg-[#1f2228]  font-[parta]'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>


                        <div className='flex flex-col gap-y-10 md:flex-row justify-center items-start'>

                            <div className='w-full md:w-[30%]'>

                                <div className=' px-2 md:px-6 flex flex-col gap-y-2 md:gap-y-12 items-center font-[poppins]'>
                                    <h3 className='font-bold text-[35px] md:text-[3.5rem] text-center flex flex-wrap items-center justify-center md:flex-col gap-x-3   md:gap-y-2 md:items-start text-white font-kumbh'>
                                        <span>Frequently</span>
                                        <span>Asked</span>
                                        <span className='text-[#ff0000]'>Question</span>
                                    </h3>

                                    <div className='font-kumbh w-full flex flex-col justify-center items-center md:items-start'>
                                        <p className='font-semibold text-start text-[22px] md:text-[20px] text-gray-200'>Still more doubts?</p>
                                        <div className='flex justify-center items-center mt-6 md:mt-10 w-full'>
                                            <button className='hover:scale-105 duration-300 w-full bg-gradient-to-r from-blue-700 to-[#ff0000] px-6 py-2 rounded-md font-semibold text-white uppercase tracking-[1px]'>Contact Us</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='w-full md:w-[80%]'>
                                <div className='w-full flex justify-center items-center  '>
                                    <div className='w-full md:px-10'>
                                        <DirectionFAQ />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* -------------------- start banner  ------------------ */}

                {/* <section className='pt-20 pb-20 bg-gray-950'>
                    <div className='w-[80%] mx-auto'>

                        <div className='flex justify-center items-center'>
                            <img src={orange} className='w-[80%] object-cover rounded-md drop-shadow-md shadow-md' alt="" />
                        </div>

                    </div>
                </section> */}



                {/* --------------------- how to appply  -------------------*/}
                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-gray-950'>

                    <div className='px-4 w-full md:w-[80%] mx-auto'>

                        <div>
                            <h3 className='text-white font-bold text-[20px] md:text-[30px] mb-3 md:mb-5 uppercase'>How to apply?</h3>

                            <div className='flex flex-col gap-y-2 text-[14px] text-gray-200'>

                                <p className='font-[roboto] text-[12px] md:text-[14px]'>To join Cinema Factory Film Academy, first, fill out the enquiry form or call us. Next, speak with our student counselor to finalize your chosen craft. Then, complete the application form and attend an interview. Upon acceptance, you'll receive an acceptance letter.</p>
                            </div>

                            <div className='flex items-center gap-x-4 md:gap-x-10 mt-4 md:mt-10 font-[poppins] text-[12px] md:text-[14px]'>
                                <div>

                                    <button
                                        class="uppercase  group relative inline-flex h-10 md:h-12 items-center justify-center overflow-hidden rounded-md bg-[#ff0000] border border-white px-6 md:px-10 font-medium text-neutral-200 duration-500"
                                    >
                                        <div class="translate-y-0 transition group-hover:-translate-y-[150%]">
                                            Apply now
                                        </div>
                                        <div class="absolute translate-y-[150%] transition group-hover:translate-y-0">
                                            Apply Now
                                        </div>
                                    </button>
                                </div>

                                <div>
                                    <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="">
                                        <button className="bg-transparent border border-white text-white shadow-md drop-shadow-md h-10 md:h-12 pl-3 rounded-md flex items-center gap-x-3 font-[poppins] text-[12px] md:text-[14px] hover:scale-105 duration-500">
                                            Download syllabus
                                            <div className='px-3 bg-green-600 rounded-r-md'>
                                                <RiWhatsappLine className='text-white h-10 md:h-12 text-[22px] font-bold' />
                                            </div>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div>

        </>
    )
}

export default Direction



