import React from 'react'
import Slider from "react-slick";
import DirectionFAQ from '../../components/Direction_FAQ';
import '../text.css'

import { FaArrowRight } from "react-icons/fa6";


import banner from '../../images/course/banner/pattern.jpg'
import frame from '../../images/course/photography/still.png'
import aravind from '../../images/mentors/Aravind.jpg'
import muniraj from '../../images/mentors/muniraj.jpg'
import light from '../../images/course/photography/still_1.png'
import who from '../../images/course/photography/still-1.png'

import orange from '../../images/banner/orange.jpg'

import para from '../../images/mentors/Prasanna.jpg'
import cj from '../../images/mentors/CJ_rajkumar.jpg'


import rajkumarOne from '../../images/poster/rajkumar/mv1.png'
import rajKumarTwo from '../../images/poster/rajkumar/mv2.png'
import rajkumarThree from '../../images/poster/rajkumar/mv3.png'
import rajkumarFour from '../../images/poster/rajkumar/mv4.png'
import rajkumarFive from '../../images/poster/rajkumar/mv5.png'
import rajkumarSix from '../../images/poster/rajkumar/mv6.png'

import prasannaOne from '../../images/poster/prasanna/mv1.png'
import prasannaTwo from '../../images/poster/prasanna/mv2.png'
import prasannaThree from '../../images/poster/prasanna/mv3.png'
import prasannaFour from '../../images/poster/prasanna/mv4.png'
import prasannaFive from '../../images/poster/prasanna/mv5.png'
import prasannaSix from '../../images/poster/prasanna/mv6.png'
import prasannaSeven from '../../images/poster/prasanna/mv7.png'
import prasannaEight from '../../images/poster/prasanna/mv8.png'
import prasannaNine from '../../images/poster/prasanna/mv9.png'
import prasannaTen from '../../images/poster/prasanna/mv10.png'




//icons
import { RiWhatsappLine } from "react-icons/ri";

import { TbCreativeCommonsBy } from "react-icons/tb";
import { PiFilmSlateDuotone } from "react-icons/pi";
import { MdLeaderboard } from "react-icons/md";
import { MdOutlineSystemSecurityUpdateGood } from "react-icons/md";
import { MdOutlineSyncProblem } from "react-icons/md";
import { RiIndeterminateCircleLine } from "react-icons/ri";

import iconOne from '../../images/course/photography/1.png'
import iconTwo from '../../images/course/photography/2.png'
import iconThree from '../../images/course/photography/3.png'
import iconFour from '../../images/course/photography/4.png'
import iconFive from '../../images/course/photography/5.png'
import iconSix from '../../images/course/photography/6.png'
import iconSeven from '../../images/course/photography/7.png'
import iconEight from '../../images/course/photography/8.png'



import PhotographyFAQ from '../../components/PhotographyFAQ';





const StillPhotography = () => {

    const settings = {
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
        },
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "30px",
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "15px",
                }
            }
        ]
    };


    return (
        <>

            <div className='font-kumbh overflow-hidden '>

                <div className='  '>
                    <img src={banner} className=' blur-[2px] w-full fixed top-0 object-cover h-screen -z-30' alt="" />
                </div>


                <section>
                    <div className=''>

                        <div>
                            <img src={frame} className='w-full object-cover' alt="" />
                        </div>

                    </div>
                </section>


                {/* -------------- course highligts ----------------- */}

                <section className='bg-gray-950'>

                    <div className='relative'>

                        <div className='relative'>
                            <img src={light} className='w-full object-cover h-screen md:h-auto' alt="" />
                            <div className='w-full backdrop-blur-sm bg-[#ffff00]/15 inset-0 absolute top-0'></div>
                        </div>

                        <div className='absolute w-full top-0'>

                            <div className='w-full px-4 md:w-[90%] mx-auto mt-10 md:mt-16'>

                                <div className='mb-8 flex flex-col items-center'>
                                    <h3 className='font-bold  text-[20px] md:text-[40px] text-white text-center uppercase tracking-[2px]'>Course highlights </h3>
                                    {/* <div className='border-2  border-orange-500 rounded-[80%] w-[30%] '></div> */}
                                </div>

                                <div className='flex justify-center items-center md:mt-12'>

                                    <div className='grid grid-cols-2 md:grid-cols-4 gap-x-6 md:gap-x-20 gap-y-4 md:gap-y-14  text-[12px] md:text-[14px]'>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full  rounded-md'>

                                                <div>
                                                    <img src={iconOne} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Award-Winning Mentors</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconTwo} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Career-Oriented</h3>
                                            </div>
                                        </div>


                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconThree} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Mobile Photography</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconFour} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Professional Course Structure</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconFive} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Flexible Schedule</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconSix} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Hands-On Experience</h3>
                                            </div>
                                        </div>


                                        <div className='hidden md:flex'>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconSeven} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Networking Opportunities</h3>
                                            </div>
                                        </div>

                                        <div className='hidden md:flex'>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6  h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconEight} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Creative Projects</h3>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                                {/* <div class=" p-4">

                                    <div class="flex flex-col grid-cols-9 p-2 mx-auto md:grid">
                                        <div class="flex md:contents flex-row-reverse">

                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Hone skills in editing and other crafts</h3>

                                            </div>

                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 rounded-t-full bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center  ">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>1</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex md:contents">
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>2</h3>
                                                </div>
                                            </div>
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-white drop-shadow-md rounded-xl col-start-6 col-end-10 mr-auto">
                                                <h3 class="text-[20px]  font-semibold  ">Integrate AI in screenplay & direction</h3>

                                            </div>
                                        </div>

                                        <div class="flex md:contents flex-row-reverse">
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-white drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  ">Perfect the art of staging characters</h3>

                                            </div>
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center justify-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300  bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>3</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex md:contents">
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col justify-center items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>4</h3>
                                                </div>
                                            </div>
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-6 col-end-10 mr-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Master the language of cinema</h3>

                                            </div>
                                        </div>


                                        <div class="flex md:contents flex-row-reverse">
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Learn techniques for filming dialogues</h3>
                                            </div>
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center justify-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300  bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>5</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}

                            </div>

                        </div>
                    </div>
                </section>



                {/* -------------- Syllabus ----------------- */}

                <section className='border-t-4 border-orange-500 pt-10 pb-16 md:pt-20 md:pb-20 bg-gray-950 -mt-6'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col gap-y-2 justify-center items-center  mb-6 md:mb-16'>
                            <h3 className='font-bold text-center text-[24px] md:text-[40px] text-white font-kumbh uppercase'>3 Month Course  </h3>
                            <p className='font-semibold text-[18px] md:text-[24px] text-[#ff0000]  font-[roboto] uppercase tracking-[1px] '> in Still Photography</p>
                        </div>

                        <div className='flex items-center'>

                            <div className='w-full md:w-[70%] mx-auto'>
                                <div>
                                    <div class="border border-gray-600 overflow-y-auto h-[25rem] scrollbar-style-one px-2 md:px-6 py-4 rounded-md overscroll-y-contain	">

                                        <div className=''>
                                            <h2 className='text-[18px] md:text-[26px] font-semibold mb-6 text-white'>Three-Month Weekend Photography Course Syllabus</h2>

                                            <div className='flex flex-col gap-y-4 md:gap-y-6'>

                                                <div className='flex flex-col gap-y-3'>
                                                    <h3 className='font-semibold text-[20px] text-gray-200'> Week 1-2: Fundamentals of Photography </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>Understanding the camera: types, functions, and settings</li>
                                                        <li>Basics of exposure: aperture, shutter speed, and ISO</li>
                                                        <li>Composition techniques and rules of framing</li>
                                                        <li>Introduction to lighting: natural and artificial light</li>
                                                        <li>Practical session: hands-on with different camera settings</li>
                                                    </ul>
                                                </div>


                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Week 3-4: Portrait Photography</h3>

                                                    <ul className=' text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>Techniques for capturing compelling portraits</li>
                                                        <li>Working with models: posing and communication</li>
                                                        <li>Use of lighting in portraits: softboxes, reflectors, and diffusers</li>
                                                        <li>Editing portraits: basic retouching and color correction</li>
                                                        <li>Practical session: portrait photoshoot with models</li>
                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>   Week 5: Modern Photography Techniques</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> Exploring modern photography trends and styles</li>
                                                        <li> Creative composition and perspective</li>
                                                        <li> Long exposure and motion photography</li>
                                                        <li> Advanced editing techniques: using Lightroom and Photoshop</li>
                                                        <li> Practical session: applying modern techniques in a photoshoot</li>
                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Week 6: Photowalk  </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> Exploring different environments and lighting conditions </li>
                                                        <li> Applying composition and exposure techniques in real-world settings </li>
                                                        <li> Engaging with subjects and capturing candid moments </li>
                                                        <li>  Practical tips for street and event photography</li>
                                                        <li>  Group photowalk to practice and refine skills</li>

                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Week 7: Practice of Lighting Techniques </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> 3-Point Lighting: Key, fill, and backlight setups</li>
                                                        <li> Rembrandt Lighting: Creating the classic Rembrandt triangle</li>
                                                        <li> Loop Lighting: Achieving the small loop shadow effect</li>
                                                        <li> Split Lighting: Dividing the face into equal halves with light and shadow</li>
                                                        <li>  Practical session: experimenting with different lighting techniques</li>
                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Week 8: Event Photography </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> Capturing events: weddings, concerts, and corporate events</li>
                                                        <li> Techniques for candid and posed shots</li>
                                                        <li> Managing low-light conditions</li>
                                                        <li> Quick editing for event delivery</li>
                                                        <li> Practical session: simulated event photography</li>

                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Week 9: Street Photography </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>  Principles of street photography </li>
                                                        <li>  Approaching and photographing strangers </li>
                                                        <li>  Composition and timing in dynamic environments </li>
                                                        <li>  Ethical considerations and legal aspects </li>
                                                        <li>   Practical session: street photography walk</li>
                                                    </ul>
                                                </div>


                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Week 10: Baby Photography </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>  Techniques for photographing infants and toddlers </li>
                                                        <li>  Creating a comfortable and safe environment </li>
                                                        <li>   Props and settings for baby photography</li>
                                                        <li>  Editing baby photos: softening and enhancing </li>
                                                        <li>  Practical session: baby photoshoot </li>

                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Week 11: Flash Photography</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> Introduction to flash photography: on-camera and off-camera flash </li>
                                                        <li> Understanding flash settings and modifiers </li>
                                                        <li> Techniques for balancing flash with ambient light </li>
                                                        <li>  Creative uses of flash in photography</li>
                                                        <li> Practical session: flash photography exercises </li>

                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Week 12: Photojournalism</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> Basics of photojournalism: storytelling through images</li>
                                                        <li>Ethics and responsibilities of a photojournalist </li>
                                                        <li> Techniques for capturing newsworthy moments</li>
                                                        <li> Editing and curating photos for publication </li>
                                                        <li>Practical session: photojournalism assignment </li>

                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Week 13: Introduction to Cinematography </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> Differences between photography and cinematography</li>
                                                        <li> Basics of video shooting: frame rates, resolution, and codecs</li>
                                                        <li> Introduction to camera movement: tripods, sliders, and gimbals</li>
                                                        <li> Basic editing and color grading for video</li>
                                                        <li> Practical session: shooting and editing a short video</li>

                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Week 14: Review and Final Project </h3>

                                                    <ul className=' text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> Review of all topics covered</li>
                                                        <li>Q&A and troubleshooting common issues</li>
                                                        <li>Planning and executing a final photography project</li>
                                                        <li>Presentation and critique of final projects</li>
                                                        <li>Course completion ceremony and certificate distribution</li>

                                                    </ul>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>



                        </div>

                    </div>
                </section>




                {/* ------------------ Mentors ------------------ */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20'>
                    <div className='w-full px-4 md:w-[80%] mx-auto font-kumbh'>

                        <div className='flex items-center justify-center mb-16'>
                            <h3 className='font-bold text-white text-[20px] md:text-[40px] text-center uppercase tracking-[2px]'>Film Makers As Mentors</h3>
                        </div>

                        <div className='flex justify-center items-center'>

                            <div className='grid grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-y-16 gap-x-20 '>

                                <div className='flex flex-col  items-center justify-center'>

                                    <div className='flex justify-center items-center'>
                                        <img src={para} className='w-[80%] rounded-md object-cover' alt="" />
                                    </div>

                                    <div className='flex flex-col gap-y-5 items-center justify-center mt-5'>
                                        {/* <div className='flex flex-col gapy-2 items-center '>
                                            <h3 className='font-bold text-[28px] text-white tracking-[2px]'>R. Prasanna Venkatesh</h3>
                                            <span className='text-orange-500 text-[16px]'>Award-winning Photographer</span>
                                        </div> */}
                                        <div className=' mx-auto '>
                                            <p className='text-[12px] md:text-[14px] text-gray-200 text-center font-[roboto]'>
                                                Mentor of our Cinematography Department, boasts 23 years of expertise in people and advertising photography, serving as the official photographer for notable events and figures such as Hillary Clinton and Rolls Royce.
                                            </p>
                                        </div>

                                        <div className='flex justify-center items-center font-[poppins] text-[12px] md:text-[14px] font-semibold'>
                                            <button className="group relative inline-flex h-11 items-center justify-center overflow-hidden rounded-md border border-gray-300 bg-[#ff0000] px-6 md:px-8 font-medium text-neutral-200 duration-500">
                                                <div className="uppercase translate-x-0 opacity-100 transition group-hover:translate-x-[-150%] group-hover:opacity-0 font-semibold font-[poppins] ">
                                                    Explore More
                                                </div>
                                                <div className="absolute translate-x-[150%] opacity-0 transition group-hover:translate-x-0 group-hover:opacity-100">
                                                    <FaArrowRight className='font-semibold text-[18px] text-white' />
                                                </div>
                                            </button>
                                        </div>

                                    </div>
                                </div>


                                <div className='flex flex-col  items-center justify-center'>

                                    <div className='flex justify-center items-center'>
                                        <img src={cj} className='w-[80%] rounded-md object-cover' alt="" />
                                    </div>

                                    <div className='flex flex-col gap-y-5 items-center justify-center mt-5'>
                                        {/* <div className='flex flex-col gapy-2 items-center '>
                                            <h3 className='font-bold text-[28px] text-white tracking-[2px]'>CJ Rajkumar</h3>
                                            <span className='text-orange-500 text-[16px]'>Renowned Cinematographer</span>
                                        </div> */}
                                        <div className=' '>
                                            <p className=' text-[12px] md:text-[14px] text-gray-200 text-center font-[roboto]'>
                                                HOD of our Cinematography Department, is a renowned cinematographer, author of 12 books, writer of over 500 articles, and an award-winning filmmaker.
                                            </p>
                                        </div>

                                        <div className='flex justify-center items-center font-[poppins] text-[12px] md:text-[14px] font-semibold'>
                                            <button className="group relative inline-flex h-11 items-center justify-center overflow-hidden rounded-md border border-gray-300 bg-[#ff0000] px-6 md:px-8 font-medium text-neutral-200 duration-500">
                                                <div className="uppercase translate-x-0 opacity-100 transition group-hover:translate-x-[-150%] group-hover:opacity-0 font-semibold font-[poppins] ">
                                                    Explore More
                                                </div>
                                                <div className="absolute translate-x-[150%] opacity-0 transition group-hover:translate-x-0 group-hover:opacity-100">
                                                    <FaArrowRight className='font-semibold text-[18px] text-white' />
                                                </div>
                                            </button>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </section>


                {/* -------------- Our Mentors have Worked In ------------------------ */}

                <section className='bg-black overflow-hidden flex justify-center items-center pt-14 pb-10 '>

                    <div className='w-full mx-auto'>

                        <div className='flex justify-center items-center mb-8 md:mb-12'>
                            <h3 className='font-bold uppercase text-[20px] md:text-[28px] text-white'>Our Mentor's Work</h3>
                        </div>

                        <div>

                            <div className="slider-container">
                                <Slider {...settings} className="">

                                    <div className='px-2'>
                                        <div>
                                            <img src={prasannaOne} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={prasannaTwo} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={prasannaThree} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={prasannaFour} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={prasannaFive} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={prasannaSix} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={prasannaSeven} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={prasannaEight} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={prasannaNine} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={prasannaTen} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>



                                    <div className='px-2'>
                                        <div>
                                            <img src={rajkumarOne} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={rajKumarTwo} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={rajkumarThree} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={rajkumarFour} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={rajkumarFive} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={rajkumarSix} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>


                                </Slider>
                            </div>

                        </div>
                    </div>
                </section>


                {/* ----------------------- who can apply -------------------------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-black'>
                    <div className='w-full px-4  md:w-[80%] mx-auto'>

                        <div className='flex justify-center items-center mb-8 md:mb-14'>
                            <h3 className='font-bold text-[20px] md:text-[35px] text-white '>Who can apply for the course? </h3>
                        </div>

                        <div className='flex flex-col md:flex-row justify-center items-center gap-y-8'>

                            <div className='w-full md:w-[40%] mx-auto'>

                                <div className='flex justify-center items-center'>
                                    <img src={who} className='w-[80%] object-cover rounded-md shadow-md drop-shadow-lg' alt="" />
                                </div>

                            </div>


                            <div className='w-full md:w-[60%] mx-auto  md:px-16'>
                                <div className='flex justify-center items-center w-full'>

                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6 md:gap-y-20 font-semibold w-full'>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <TbCreativeCommonsBy className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Creative Thinker</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <PiFilmSlateDuotone className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Film Enthusiast</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <MdLeaderboard className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> A leader</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>

                                                    <MdOutlineSystemSecurityUpdateGood className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px] ' > Good Communicator</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <MdOutlineSyncProblem className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'>  Problem Solver</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <RiIndeterminateCircleLine className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Determined Person</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* ------------------------------ FAQ ----------------------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-[#1f2228]  font-[parta]'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>


                        <div className='flex flex-col gap-y-10 md:flex-row justify-center items-start'>


                            <div className='w-full md:w-[30%]'>

                                <div className=' px-2 md:px-6 flex flex-col gap-y-2 md:gap-y-12 items-center font-[poppins]'>
                                    <h3 className='font-bold text-[35px] md:text-[3.5rem] text-center flex flex-wrap items-center justify-center md:flex-col gap-x-3   md:gap-y-2 md:items-start text-white font-kumbh'>
                                        <span>Frequently</span>
                                        <span>Asked</span>
                                        <span className='text-[#ff0000]'>Question</span>
                                    </h3>

                                    <div className='font-kumbh w-full flex flex-col justify-center items-center md:items-start'>
                                        <p className='font-semibold text-start text-[22px] md:text-[20px] text-gray-200'>Still more doubts?</p>
                                        <div className='flex justify-center items-center mt-6 md:mt-10 w-full'>
                                            <button className='hover:scale-105 duration-300 w-full bg-gradient-to-r from-blue-700 to-[#ff0000] px-6 py-2 rounded-md font-semibold text-white uppercase tracking-[1px]'>Contact Us</button>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='w-full md:w-[80%]'>
                                <div className='flex justify-center items-center  w-full'>
                                    <div className='w-full md:px-10'>
                                        <PhotographyFAQ />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* -------------------- start banner  ------------------ */}

                {/* <section className='pt-20 pb-20 bg-gray-950'>
                    <div className='w-[80%] mx-auto'>

                        <div className='flex justify-center items-center'>
                            <img src={orange} className='w-[80%] object-cover rounded-md drop-shadow-md shadow-md' alt="" />
                        </div>

                    </div>
                </section> */}



                {/* --------------------- how to appply  -------------------*/}
                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-gray-950'>

                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div>
                            <h3 className='text-white font-bold text-[20px] md:text-[30px] mb-5 uppercase'>How to apply?</h3>

                            <div className='flex flex-col gap-y-2 text-[12px] md:text-[14px] text-gray-200'>
                                <p className='font-[roboto]'>To join Cinema Factory Film Academy, first, fill out the enquiry form or call us. Next, speak with our student counselor to finalize your chosen craft. Then, complete the application form and attend an interview. Upon acceptance, you'll receive an acceptance letter.</p>
                            </div>

                            <div className='flex items-center gap-x-4 md:gap-x-10 mt-4 md:mt-10 font-[poppins] text-[12px] md:text-[14px]'>
                                <div>

                                    <button
                                        class="uppercase  group relative inline-flex h-10 md:h-12 items-center justify-center overflow-hidden rounded-md bg-[#ff0000] border border-white px-6 md:px-10 font-medium text-neutral-200 duration-500"
                                    >
                                        <div class="translate-y-0 transition group-hover:-translate-y-[150%]">
                                            Apply now
                                        </div>
                                        <div class="absolute translate-y-[150%] transition group-hover:translate-y-0">
                                            Apply Now
                                        </div>
                                    </button>
                                </div>

                                <div>
                                    <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="">
                                        <button className="bg-transparent border border-white text-white shadow-md drop-shadow-md h-10 md:h-12 pl-3 rounded-md flex items-center gap-x-3 font-[poppins] text-[12px] md:text-[14px] hover:scale-105 duration-500">
                                            Download syllabus
                                            <div className='px-3 bg-green-600 rounded-r-md'>
                                                <RiWhatsappLine className='text-white h-10 md:h-12 text-[22px] font-bold' />
                                            </div>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div>

        </>
    )
}

export default StillPhotography



