import React from 'react'
import { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { FaWhatsapp } from "react-icons/fa";


// import logo from '../images/64.png'
import logo from '../images/cf_logo.webp'
import black from '../images/footer_logo-1.png'


const Header = () => {

    // State to manage the navbar's visibility
    const [nav, setNav] = useState(false);


    // Toggle function to handle the navbar's display
    const handleNav = () => {
        setNav(!nav);
    };



    //color change
    const [navbarBg, setNavbarBg] = useState('bg-black/80');


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setNavbarBg('bg-black/80 shadow-md ');
            } else {
                setNavbarBg('bg-black/90');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    function topPage() {
        window.scroll(0, 0)
    }

    //mobile view click the link hide the navbar

    const [hide, setHide] = useState(true)

    // function clicklink() {
    //     setHide(false)
    // }


    return (
        <>

            <div className='bg-[#ffA500] py-2 px-1'>
                <div>

                    <div className='flex items-center justify-center gap-x-14 md:gap-x-24 font-[roboto] px-1 md:px-0 '>
                        <p className='font-semibold text-[12px]  md:text-[16px]  capitalize text-black'>Your filmmaking future starts now </p>
                        <div>
                            <Link to='contact' onClick={topPage}>
                                <button className='bg-[#ff0000] hover:bg-transparent text-white hover:text-black duration-500 hover:border hover:border-white rounded-md px-3 md:px-6  py-1 md:py-1.5 text-[12px] md:text-[14px]  font-semibold'>Join Now</button>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

            {/* -------- second navbar ------- */}

            <nav className={` w-full sticky top-0 z-30  py-6  shadow-sm shadow-gray-400  md:py-7 ${navbarBg} `}>
                <div className="max-w-full mx-auto font-kumbh  ">
                    {/* Logo */}
                    <div className='flex justify-between items-center px-4 sm:px-9 w-full xl:w-[90%] mx-auto'>


                        <div className='w-full md:w-fit'>
                            <Link to='/' onClick={topPage}>
                                <img src={logo} className=' w-64 sm:w-64 md:w-72 object-cover' alt="" />
                            </Link>
                        </div>


                        {/* Desktop Navigation */}
                        <ul className='hidden lg:flex w-full md:w-fit font-semibold gap-x-4 xl:gap-x-10' >


                            <li className='  cursor-pointer duration-300 text-white  text-[12px] xl:text-[14px] ' >
                                <Link to="/" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-white/50  focus:text-red-600 tracking-[1px] font-semibold">Home</button>
                                    </p>
                                </Link>
                            </li>


                            <li className='  cursor-pointer duration-300 text-white  text-[12px] xl:text-[14px] ' >
                                <Link to="about" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-white/50  focus:text-red-600 tracking-[1px] font-semibold">About Us</button>
                                    </p>
                                </Link>
                            </li>

                            <li className='  cursor-pointer duration-300 text-white  text-[12px] xl:text-[14px] ' >
                                <Link to="admission" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-white/50  focus:text-red-600 tracking-[1px] font-semibold">Admission</button>
                                    </p>
                                </Link>
                            </li>

                            {/* <li className='  cursor-pointer duration-300 text-white  text-[12px] xl:text-[14px]' >

                                <ScrollLink to="fourteen" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class=" hover:text-white/50  focus:text-red-600 tracking-[1px] font-semibold">14 Crafts</button>
                                    </p>
                                </ScrollLink>

                            </li> */}
                            <li className='  cursor-pointer duration-300 text-white  text-[12px] xl:text-[14px]' >

                                <div to="" smooth={true} duration={500} onClick={topPage} className='relative group'>

                                    <p className="relative group flex items-center">
                                        <button class="  hover:text-white/50  focus:text-red-600 tracking-[1px] font-semibold">Courses</button>
                                    </p>

                                    <div
                                        class="absolute left-0 w-64 mt-1 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition duration-300">
                                        <div class=" overflow-hidden">
                                            <Link to='direction'>
                                                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Direction</a>
                                            </Link>
                                            <Link to='cinematography'>
                                                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Cinematography</a>
                                            </Link>
                                            <Link to='vfx'>
                                                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Visual Effects & CG</a>
                                            </Link>
                                            <Link to='editing'>
                                                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Editing</a>
                                            </Link>
                                            <Link to='acting'>
                                                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Acting</a>
                                            </Link>
                                            <Link to='still_photography'>
                                                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Still Photography</a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </li>


                            {/* <li className=' cursor-pointer duration-300 text-white  text-[12px] xl:text-[14px]' >

                                <ScrollLink to="mentors" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="  hover:text-white/50  focus:text-red-600 tracking-[1px] font-semibold">Mentors</button>
                                    </p>
                                </ScrollLink>
                            </li> */}

                            <li className=' cursor-pointer duration-300 text-white  text-[12px] xl:text-[14px]' >

                                <Link to="contact" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="  hover:text-white/50  focus:text-red-600 tracking-[1px] font-semibold">Contact</button>
                                    </p>
                                </Link>
                            </li>

                        </ul>

                        <div className='cursor-pointer ml-10 md:ml-0 w-full md:w-fit'>
                            {/* <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="">

                                <div className=' w-6 h-6 md:h-8 md:w-8 bg-green-600 rounded-full duration-300 shadow-sm drop-shadow-md shadow-gray-100 hover:shadow-green-200 hover:shadow-sm hover:drop-shadow-md hover:scale-105 flex  justify-center items-center  '>
                                    <FaWhatsapp className=' rounded-full text-[16px] md:text-[21px]  text-white' />
                                </div>
                            </a> */}
                            <Link to='contact' onClick={topPage}>
                                <button className='font-semibold bg-[#ff0000] hover:text-white tracking-[1px] px-4 md:px-6  py-1.5 md:py-2.5 uppercase text-[12px] md:text-[15px] rounded-sm text-gray-100  hover:bg-red-600'>Apply Now</button>
                            </Link>
                        </div>


                        {/* Mobile Navigation Icon */}
                        <div onClick={handleNav} className='block lg:hidden'>
                            {nav ? <AiOutlineClose className='w-16 sm:w-16 md:w-20 text-gray-50' /> : <AiOutlineMenu className='w-16 sm:w-16 md:w-20 text-gray-50' />}
                        </div>

                        {/* Mobile Navigation Menu */}
                        {
                            hide &&
                            <ul
                                className={
                                    nav
                                        ? 'fixed lg:hidden left-0 top-0 w-[75%] h-[100%] border-r  bg-gray-50 ease-in-out duration-500'
                                        : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
                                }
                            >
                                {/* Mobile Logo */}

                                <Link to='/' onClick={topPage} className='w-full' >
                                    <img src={black} className=' w-36 sm:w-40  pt-10  ml-10' alt="" onClick={handleNav} />
                                </Link>



                                {/* Mobile Navigation Items */}

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                    <Link to="/" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Home</button>
                                        </p>
                                    </Link>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <Link to="about" smooth={true} duration={500} onClick={topPage}>

                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>About Us</button>
                                        </p>
                                    </Link >

                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <Link to="admission" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold  tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Admission</button>
                                        </p>
                                    </Link>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <div to="" smooth={true} duration={500} onClick={topPage} className='relative group'>

                                        <p className="relative group flex items-center">
                                            <button class="  font-semibold  tracking-[.02em]  hover:text-white/50  focus:text-red-600">Courses</button>
                                        </p>

                                        <div
                                            className="absolute left-0 w-52 mt-1 origin-top-left z-10 bg-white divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition duration-300">

                                            <div className="overflow-hidden">
                                                <Link to="direction" onClick={topPage} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Direction
                                                </Link>
                                                <Link to="cinematography" onClick={topPage} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Cinematography
                                                </Link>
                                                <Link to="vfx" onClick={topPage} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Visual Effects & CG
                                                </Link>
                                                <Link to="editing" onClick={topPage} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Editing
                                                </Link>
                                                <Link to="acting" onClick={topPage} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Acting
                                                </Link>
                                                <Link to="still_photography" onClick={topPage} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Still Photography
                                                </Link>
                                            </div>
                                        </div>


                                    </div>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <Link to="contact" smooth={true} duration={500} onClick={topPage}>

                                        <p className="relative group flex items-center">
                                            <button class="font-semibold  tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Contact</button>
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        }

                    </div>
                </div>
            </nav>

        </>
    )
}

export default Header