import React from 'react'
import Slider from "react-slick";
import '../text.css'

import { FaArrowRight } from "react-icons/fa6";

import banner from '../../images/course/banner/pattern.jpg'
import frame from '../../images/course/banner/acting.png'
import light from '../../images/course/acting/lighting_banner.png'
import who from '../../images/course/acting/joker.png'


//mentors
import raja from '../../images/mentors/Raja_mohhamed.jpg'
import orange from '../../images/banner/orange.jpg'

import posterOne from '../../images/poster/mv1.jpg'
import posterTwo from '../../images/poster/mv2.jpg'
import posterThree from '../../images/poster/mv3.png'
import posterFour from '../../images/poster/mv4.png'


//icons
import { RiWhatsappLine } from "react-icons/ri";

import { MdNaturePeople } from "react-icons/md";
import { MdEditOff } from "react-icons/md";
import { FaHeadSideVirus } from "react-icons/fa6";
import { GiTeamIdea } from "react-icons/gi";
import { BiCommentDetail } from "react-icons/bi";
import { MdOutlineManageHistory } from "react-icons/md";

import { PiMaskHappyBold } from "react-icons/pi";


import iconOne from '../../images/course/acting/1.png'
import iconTwo from '../../images/course/acting/2.png'
import iconThree from '../../images/course/acting/3.png'
import iconFour from '../../images/course/acting/5.png'
import iconFive from '../../images/course/acting/5.png'
import iconSix from '../../images/course/acting/6.png'
import iconSeven from '../../images/course/acting/7.png'
import iconEight from '../../images/course/acting/8.png'


import sara from '../../images/mentors/saravanan.jpg'

import { MdLeaderboard } from "react-icons/md";

import FAQ from '../../components/FAQ';
import CinematographyFAQ from '../../components/Cinematography_FAQ';
import EditingFAQ from '../../components/EditingFAQ';
import ActingFAQ from '../../components/ActingFAQ';



const Acting = () => {

    const settings = {
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
        },
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "30px",
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "15px",
                }
            }
        ]
    };



    return (
        <>

            <div className='font-kumbh overflow-hidden '>

                <div className='  '>
                    <img src={banner} className=' blur-[2px] w-full fixed top-0 object-cover h-screen -z-30' alt="" />
                </div>


                <section>
                    <div className=''>

                        <div>
                            <img src={frame} className='w-full object-cover' alt="" />
                        </div>

                    </div>
                </section>


                {/* -------------- course highligts ----------------- */}

                <section className='bg-gray-950'>

                    <div className='relative'>

                        <div className='relative'>
                            <img src={light} className='w-full object-cover h-screen md:h-auto' alt="" />
                            <div className='w-full backdrop-blur-sm bg-blue-900/15 inset-0 absolute top-0'></div>
                        </div>

                        <div className='absolute w-full top-0'>

                            <div className='w-full px-4 md:w-[90%] mx-auto mt-10 md:mt-16'>

                                <div className='mb-8 flex flex-col items-center'>
                                    <h3 className='font-bold text-[20px] md:text-[40px] text-white text-center uppercase tracking-[2px]'>Course highlights </h3>
                                    {/* <div className='border-2  border-orange-500 rounded-[80%] w-[30%] '></div> */}
                                </div>

                                <div className='flex justify-center items-center md:mt-12'>


                                    <div className='grid grid-cols-2 md:grid-cols-4 gap-x-6 md:gap-x-14 gap-y-4 md:gap-y-14  text-[12px] md:text-[14px]'>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6  rounded-md'>

                                                <div>
                                                    <img src={iconOne} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'> Audition techniques and presentation </h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6  rounded-md'>

                                                <div>
                                                    <img src={iconTwo} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Unique weekend course designed for</h3>
                                            </div>
                                        </div>


                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6  rounded-md'>

                                                <div>
                                                    <img src={iconThree} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'> Scenes, exercises, and performances. </h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6  rounded-md'>

                                                <div>
                                                    <img src={iconFour} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Acting basics from scratch</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6  rounded-md'>

                                                <div>
                                                    <img src={iconFive} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Personalized attention and guidance in a film environment.</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6  rounded-md'>

                                                <div>
                                                    <img src={iconSix} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>  Learn the craft of acting for film and television. .</h3>
                                            </div>
                                        </div>


                                        <div className='hidden md:flex'>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6  rounded-md'>

                                                <div>
                                                    <img src={iconSeven} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'> Film aspirants: Learn the craft of acting for film and television.  </h3>
                                            </div>
                                        </div>

                                        <div className='hidden md:flex'>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6  rounded-md'>

                                                <div>
                                                    <img src={iconEight} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'> Holistic approach to develop your overall personality,  </h3>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {/* <div class=" p-4">

                                    <div class="flex flex-col grid-cols-9 p-2 mx-auto md:grid">
                                        <div class="flex md:contents flex-row-reverse">

                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Hone skills in editing and other crafts</h3>

                                            </div>

                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 rounded-t-full bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center  ">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>1</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex md:contents">
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>2</h3>
                                                </div>
                                            </div>
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-white drop-shadow-md rounded-xl col-start-6 col-end-10 mr-auto">
                                                <h3 class="text-[20px]  font-semibold  ">Integrate AI in screenplay & direction</h3>

                                            </div>
                                        </div>

                                        <div class="flex md:contents flex-row-reverse">
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-white drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  ">Perfect the art of staging characters</h3>

                                            </div>
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center justify-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300  bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>3</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex md:contents">
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col justify-center items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>4</h3>
                                                </div>
                                            </div>
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-6 col-end-10 mr-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Master the language of cinema</h3>

                                            </div>
                                        </div>


                                        <div class="flex md:contents flex-row-reverse">
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Learn techniques for filming dialogues</h3>
                                            </div>
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center justify-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300  bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>5</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}
                            </div>

                        </div>

                    </div>


                </section>



                {/* -------------- Syllabus ----------------- */}

                <section className='border-t-4 border-orange-500 pt-16 pb-10 md:pt-20 md:pb-20 bg-gray-950 -mt-6'>
                    <div className=' w-full px-4 md:w-[85%] mx-auto'>



                        <div className='flex flex-col md:flex-row items-center gap-y-14'>

                            <div className=' w-full md:w-[50%] md:px-5 mx-auto'>

                                <div className='flex flex-col gap-y-2 justify-center items-center mb-6 md:mb-16'>
                                    <h3 className='font-bold text-center text-[24px] md:text-[40px] text-white font-kumbh uppercase'>6 Month  Course  </h3>
                                    <p className='font-semibold text-[18px] md:text-[24px] text-[#ff0000]  font-[roboto] uppercase tracking-[1px] '> in Acting</p>
                                </div>

                                <div className=''>

                                    <div class="border border-gray-600 overflow-y-auto h-[25rem] scrollbar-style-one px-2 md:px-6 py-4 rounded-md overscroll-y-contain	">

                                        <div className=''>
                                            <h2 className='text-[18px] md:text-[26px] font-semibold mb-6 text-white'>6 Month Sylllabus</h2>

                                            <div className='flex flex-col gap-y-4 md:gap-y-6'>

                                                <div className='flex flex-col gap-y-3'>
                                                    <h3 className='font-semibold text-[18px] md:text-[20px] text-gray-200'> Acting</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-4 text-gray-400'>

                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span>Physical movements </li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span> Mind and body oriented activities</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span> Voice culture</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' /> </span> Method acting (Stanislavski system) </li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span> Navarasam</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' /> </span>  Character improvisations</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' /> </span>Acting for the camera </li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />   </span> Audition techniques</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span> Storytelling and narrations</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span>Realistic acting training with scripts basis </li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span> Character study</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span>Combined with other department students to do practical classes </li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span>Acting Theory </li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span>Photo shoot for students to make a portfolio </li>

                                                    </ul>
                                                </div>



                                                <div className='flex flex-col gap-y-3'>
                                                    <h3 className='font-semibold  text-[18px] md:text-[20px] text-gray-200'>  Project</h3>

                                                    <ul className=' text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-4 text-gray-400'>

                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span>Types of Conflicts</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span>Exploring Film Genres</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span>Plot Development</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span>Character Development</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span> Theme Exploration</li>
                                                    </ul>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>


                            <div className='w-full md:w-[50%] md:px-5 mx-auto'>

                                <div className='flex flex-col gap-y-2 justify-center items-center mb-6 md:mb-16'>
                                    <h3 className='font-bold text-center text-[24px] md:text-[40px] text-white font-kumbh uppercase'>3 Month  Course  </h3>
                                    <p className='font-semibold text-[18px] md:text-[24px] text-[#ff0000]  font-[roboto] uppercase tracking-[1px] '> in Acting</p>
                                </div>

                                <div className=''>

                                    <div class="border border-gray-600 overflow-y-auto h-[25rem] scrollbar-style-one px-2 md:px-6 py-4 rounded-md overscroll-y-contain	">

                                        <div className=''>
                                            <h2 className='text-[18px] md:text-[24px] font-semibold mb-6 text-white'>3 Month Sylllabus</h2>

                                            <div className='flex flex-col gap-y-6'>

                                                <div className='flex flex-col gap-y-3'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Acting</h3>

                                                    <ul className='text-[14px] font-[roboto] flex flex-col gap-y-4 text-gray-400'>

                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span>Physical movements </li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span> Mind and body oriented activities</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span> Voice culture</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' /> </span> Method acting (Stanislavski system) </li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />  </span> Navarasam</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' /> </span>  Character improvisations</li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' /> </span>Acting for the camera </li>
                                                        <li className='flex items-center gap-x-3 md:gap-x-5'> <span><PiMaskHappyBold className='text-gray-200 text-[16px] md:text-[20px]' />   </span> Audition techniques</li>

                                                    </ul>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>
                </section >



                {/* ------------------ Mentors ------------------ */}

                < section className='pt-10 md:pt-20 pb-10 md:pb-20' >
                    <div className='w-full px-4 md:w-[80%] mx-auto font-kumbh'>

                        <div className='flex items-center justify-center mb-6 md:mb-10'>
                            <h3 className='font-bold text-white text-[20px] md:text-[40px] text-center uppercase tracking-[2px]'>Film Maker As Mentor</h3>
                        </div>

                        <div className='flex justify-center items-center'>

                            <div className='grid grid-cols-1 '>

                                <div className='flex flex-col  items-center justify-center'>

                                    <div className='flex justify-center items-center'>
                                        <img src={sara} className='w-[80%] rounded-md object-cover' alt="" />
                                    </div>

                                    <div className='flex flex-col gap-y-5 items-center justify-center mt-5'>
                                        {/* <div className='flex flex-col gapy-2 items-center '>
                                            <h3 className='font-bold text-[28px] text-white tracking-[2px]'>Raja Mohammed</h3>
                                            <span className='text-orange-500 text-[16px]'>Editing</span>
                                        </div> */}

                                        <div className='w-full md:w-[70%] mx-auto '>
                                            <p className=' text-[12px] md:text-[14px] text-gray-200 text-center'>
                                                Saravanan J is a seasoned acting instructor with extensive experience in teaching, directing, and acting across various media.
                                                He has led numerous workshops and coordinated acting courses at esteemed institutions, shaping the careers of many aspiring actors.
                                            </p>
                                        </div>

                                        <div className='flex justify-center items-center font-[poppins] text-[14px] md:text-[16px] font-semibold'>
                                            <button className="group relative inline-flex h-12 items-center justify-center overflow-hidden rounded-md border border-gray-300 bg-[#ff0000] px-8 md:px-12 font-medium text-neutral-200 duration-500">
                                                <div className="uppercase translate-x-0 opacity-100 transition group-hover:translate-x-[-150%] group-hover:opacity-0 font-semibold font-[poppins] ">
                                                    Explore More
                                                </div>
                                                <div className="absolute translate-x-[150%] opacity-0 transition group-hover:translate-x-0 group-hover:opacity-100">
                                                    <FaArrowRight className='font-semibold text-[18px] text-white' />
                                                </div>
                                            </button>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </section >



                {/* -------------- Our Mentors have Worked In ------------------------ */}

                < section className='bg-black overflow-hidden flex justify-center items-center pt-14 pb-10 ' >

                    <div className='w-full mx-auto'>

                        <div className='flex justify-center items-center mb-12'>
                            <h3 className='font-bold uppercase text-[20px] md:text-[28px] text-white'>Our Mentor's Work</h3>
                        </div>

                        <div>

                            <div className="slider-container">
                                <Slider {...settings} className="">

                                    <div className='px-2'>
                                        <div>
                                            <img src={posterOne} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>

                                    <div className='px-2'>
                                        <div>
                                            <img src={posterTwo} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>

                                    <div className='px-2'>
                                        <div>
                                            <img src={posterThree} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>

                                    <div className='px-2'>
                                        <div>
                                            <img src={posterFour} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>

                                </Slider>
                            </div>

                        </div>
                    </div>
                </section >


                {/* ----------------------- who can apply -------------------------- */}

                < section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-black' >
                    <div className=' w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex justify-center items-center mb-8 md:mb-14'>
                            <h3 className='font-bold text-[20px] text-center md:text-[35px] text-white '>Who can apply for the course? </h3>
                        </div>

                        <div className='flex flex-col md:flex-row justify-center items-center gap-y-8'>

                            <div className='w-full md:w-[40%] mx-auto'>

                                <div className='flex justify-center items-center'>
                                    <img src={who} className='w-[80%] object-cover rounded-md shadow-md drop-shadow-lg' alt="" />
                                </div>

                            </div>

                            <div className='w-full md:w-[60%] mx-auto md:px-16'>
                                <div className='flex justify-center items-center w-full'>

                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6 md:gap-y-20 font-semibold w-full' >

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <MdNaturePeople className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'>  Passionate performers </h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <MdEditOff className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Storytellers </h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <FaHeadSideVirus className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Creative souls </h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>

                                                    <GiTeamIdea className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px] ' > Drama enthusiasts </h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <BiCommentDetail className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Bold personalities </h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <MdOutlineManageHistory className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Film Enthusiast </h3>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >



                {/* ------------------------------ FAQ ----------------------- */}

                < section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-[#1f2228]  font-[parta]' >
                    <div className='w-full px-4 md:w-[80%] mx-auto'>


                        <div className='flex flex-col gap-y-10 md:flex-row justify-center items-start'>

                            <div className='w-full md:w-[30%]'>

                                <div className=' px-2 md:px-6 flex flex-col gap-y-2 md:gap-y-12 items-center font-[poppins]'>
                                    <h3 className='font-bold text-[35px] md:text-[3.5rem] text-center flex flex-wrap items-center justify-center md:flex-col gap-x-3   md:gap-y-2 md:items-start text-white font-kumbh'>
                                        <span>Frequently</span>
                                        <span>Asked</span>
                                        <span className='text-[#ff0000]'>Question</span>
                                    </h3>

                                    <div className='font-kumbh w-full flex flex-col justify-center items-center md:items-start'>
                                        <p className='font-semibold text-start text-[22px] md:text-[20px] text-gray-200'>Still more doubts?</p>
                                        <div className='flex justify-center items-center mt-6 md:mt-10 w-full'>
                                            <button className='hover:scale-105 duration-300 w-full bg-gradient-to-r from-blue-700 to-[#ff0000] px-6 py-2 rounded-md font-semibold text-white uppercase tracking-[1px]'>Contact Us</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='w-full md:w-[80%]'>
                                <div className='w-full flex justify-center items-center  '>
                                    <div className='w-full md:px-10'>
                                        <ActingFAQ />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section >


                {/* -------------------- start banner  ------------------ */}

                {/* < section className='pt-20 pb-20 bg-gray-950' >
                    <div className='w-[80%] mx-auto'>

                        <div className='flex justify-center items-center'>
                            <img src={orange} className='w-[80%] object-cover rounded-md drop-shadow-md shadow-md' alt="" />
                        </div>

                    </div>
                </section > */}



                {/* --------------------- how to appply  -------------------*/}
                < section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-gray-950' >

                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div>
                            <h3 className='text-white font-bold text-[20px] md:text-[30px] mb-5 uppercase'>How to apply?</h3>

                            <div className='flex flex-col gap-y-2 text-[12px] md:text-[14px] text-gray-200'>
                                <p className='font-[roboto]'>To join Cinema Factory Film Academy, first, fill out the enquiry form or call us. Next, speak with our student counselor to finalize your chosen craft. Then, complete the application form and attend an interview. Upon acceptance, you'll receive an acceptance letter.</p>
                            </div>

                            <div className='flex items-center gap-x-4 md:gap-x-10 mt-4 md:mt-10 font-[poppins] text-[12px] md:text-[14px]'>
                                <div>

                                    <button
                                        class="uppercase  group relative inline-flex h-10 md:h-12 items-center justify-center overflow-hidden rounded-md bg-[#ff0000] border border-white px-6 md:px-10 font-medium text-neutral-200 duration-500"
                                    >
                                        <div class="translate-y-0 transition group-hover:-translate-y-[150%]">
                                            Apply now
                                        </div>
                                        <div class="absolute translate-y-[150%] transition group-hover:translate-y-0">
                                            Apply Now
                                        </div>
                                    </button>
                                </div>

                                <div>
                                    <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="">
                                        <button className="bg-transparent border border-white text-white shadow-md drop-shadow-md h-10 md:h-12 pl-3 rounded-md flex items-center gap-x-3 font-[poppins] text-[12px] md:text-[14px] hover:scale-105 duration-500">
                                            Download syllabus
                                            <div className='px-3 bg-green-600 rounded-r-md'>
                                                <RiWhatsappLine className='text-white h-10 md:h-12 text-[22px] font-bold' />
                                            </div>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </section >

            </div >

        </>
    )
}

export default Acting



