import React, { useEffect } from 'react'
import Slider from "react-slick";
import './text.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

import Clients from '../components/Clients'
import videoBanner from '../images/videos/video.mp4'
import stop from '../images/stop.jpg'

import banner from '../images/banner/main 1.jpg'
import background from '../images/banner/course.jpg'
import bannerOne from '../images/banner/banner_1.jpg'
import price from '../images/banner/price.jpg'
import highbackground from '../images/banner/banner1.jpg'

import coll from '../images/coll.jpg'
import whycf from '../images/why_cf.jpg'

import creative from '../images/banner/creative.jpg'
import get from '../images/banner/get.jpg'

import webser from '../images/icons/webseries2.png'
import over from '../images/icons/overseas2.png'
import internship from '../images/icons/Internship.png'


//text
import master from '../images/master.png'
import overseas from '../images/Overseas.png'
import shoot from '../images/shoot.png'
import work from '../images/text/proffessional.png'
import why from '../images/text/why.png'
import question from '../images/text/question.png'
import mentors from '../images/text/mentors.png'
import coursenew from '../images/text/coursenew.png'
import mentrswork from '../images/text/mentors_work.png'
import our from '../images/text/knowledge_partner.png'
import infras from '../images/text/infrastructure.png'
import aitext from '../images/text/1st_ai.png'
import emitext from '../images/text/EMI_facilities.png'
import globaltext from '../images/text/Global_trainers.png'
import syllabustext from '../images/text/customized_syllabus.png'
import awardtext from '../images/text/award_winning.png'
import internshiptext from '../images/text/internship_1.png'
import certicatetext from '../images/text/prestigious certifications.png'


import content from '../images/content_two.png'
import header from '../images/header.png'
import one from '../images/two.jpg'
import two from '../images/three.jpg'
import three from '../images/four.jpg'

// highlights
import emi from '../images/icons/emi.png'
import ai from '../images/icons/AI.png'
import certicate from '../images/icons/certification.png'
import connect from '../images/icons/connectivity.png'
import trainer from '../images/icons/industry_trainer.png'
import syllabus from '../images/icons/Customized_syllabus.png'
import award from '../images/icons/award_winning.png'



import video from '../images/banner/cinema.mp4'
import web from '../images/banner/web.mp4'



//icons
import { MdOutlineCameraAlt } from "react-icons/md";
import Course from '../components/Course';
import Works from '../components/Works';
import ContactForm from '../components/ContactForm';
import FAQ from '../components/FAQ';
import Mentors from '../components/Mentors';
import Try from './Try';
import Infra from '../components/Infra';
import MovieLogo from '../components/MovieLogo';
import { Link } from 'react-router-dom';




const Home = () => {

    const text = "what's stopping you?";

    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])

    const handleContextMenu = (event) => {
        event.preventDefault(); // Prevents the context menu from appearing
    };

    function topPage() {
        window.scroll(0, 0)
    }

    return (
        <>

            <div className='overflow-hidden ' onContextMenu={handleContextMenu}>

                <div className=''>
                    <img src={banner} className='w-full fixed top-0 h-screen -z-20' alt="" />
                </div>


                <section>
                    <div className='font-playfair'>

                        <div className='relative w-full'>

                            <div>
                                <div className='relative'>
                                    <img src={banner} className='w-full object-cover blur-[]' alt="" />
                                </div>
                            </div>

                            {/* <div className='absolute top-40 px-8 flex justify-center items-center w-full'>
                                <h3 className='font-bold text-[4rem] text-white uppercase '>Your Cinematic Future Starts Here</h3>
                            </div> */}
                        </div>

                    </div>
                </section>



                <section>
                    {/* font-[Limelight]  */}
                    <div className=' font-[Aladin]'>

                        <div className='relative w-full'>

                            <div>
                                <img src={bannerOne} className='w-full object-cover blur-[2px]' alt="" />
                            </div>

                            <div className='absolute top-12 md:top-60  flex justify-center items-center w-full' >

                                <div className='flex flex-col gap-y-2 md:gap-y-8  text-white'>

                                    {/* <p className='text-[35px] text-center  flex items-center gap-x-10 justify-center '> <span>9+ Award Winners</span> <span>|</span> <span>6 Courses</span>  <span> |</span> <span> ∞ Opportunities</span> <span>|</span>  <span>50+ yrs experience</span> </p> */}
                                    {/* <p className='w-[60%] mx-auto text-center mt-6 text-[18px] border-b border-t border-gray-100 py-12'>To all Aspiring Directors, Cinematographers, Editor, VFX Artists, Actors, Colorist.  Start your filmmaking journey from the most prestigious institute of India.</p> */}

                                    <div className='flex flex-col gap-y-6 md:gap-y-14 justify-center items-center '>
                                        <div className='flex justify-center'>
                                            <img src={header} className=' w-[90%] md:w-[80%] object-cover ' alt="" />
                                        </div>
                                        <img src={content} className='w-[70%] md:w-[50%] object-cover ' alt="" />
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </section>



                {/* ---------------------------- courses -------------------------- */}

                <section className=''>
                    <div className=' px-4 mx-auto w-full bg-black pt-10 md:pt-20 pb-10 md:pb-20 '>

                        <div>
                            {/* <h3 className='uppercase font-semibold  text-[2rem] text-center text-white tracking-[3px]'>Courses</h3> */}
                            <div className='flex justify-center items-center'>
                                <img src={coursenew} className=' w-[40%] md:w-[34%] object-cover' alt="" />
                            </div>
                            <p className='text-center text-[12px] md:text-[16px] text-gray-100 mt-4 font-[roboto] w-full md:w-[60%] mx-auto'>Our academy offers workshops, seminars, webinars, and various courses, each tailored for comprehensive knowledge and practical skills. Click below for more info.</p>
                        </div>

                        <div className=' mt-8 md:mt-16 px-3'>
                            {/* <Try /> */}
                            <Course />
                        </div>


                    </div>
                </section>


                {/* ------------- why cinema factory  ---------------*/}

                <section className='font-[Prata] '>
                    <div class=" inset-0 -z-10 h-full w-full items-center  [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#bf9b38_100%)]">
                        <div className='w-full mx-auto'>

                            <div className='flex items-center justify-between'>

                                <div className='relative w-full mx-auto'>

                                    <div className='flex justify-center '>
                                        <img src={whycf} className='w-full  object-cover' alt="" />
                                    </div>

                                    <div className='absolute w-full flex flex-col gap-y-5 justify-center items-center bottom-8 '>
                                        <img src={why} className='w-[75%] object-cover md:mb-32' alt="" />

                                        <div>
                                            <h3 className=' mb-2 text-center  text-[10px] md:text-[24px] tracking-[1px]  md:tracking-[4px] font-kumbh   capitalize text-gray-100 font-semibold'> Elevate Your Filmmaking With Cinema Factory  </h3>
                                            {/* <p className='mt-4 text-[32px] font-bold  text-center text-white '>MASTER WEB SERIES PRODUCTION AND OVERSEAS SHOOTS</p> */}
                                            <div className='flex justify-center'>
                                                <img src={master} className='w-[90%] md:w-[80%] object-cover' alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className='w-[50%] mx-auto'>
                                    <div className='flex flex-col gap-y-3 px-24'>
                                        <div>
                                            <img src={why} className='w-full object-cover' alt="" />
                                        </div>
                                        <p className='font-normal  text-center text-gray-50 font-[roboto] leading-7 text-[14px]'>Cinema Factory offers a comprehensive film education with a competitive fee structure, international exposure, and hands-on production experience, all guided by active industry professionals. Students also receive prestigious national-level certifications, setting them apart in the competitive film industry.</p>
                                    </div>
                                </div> */}

                            </div>

                        </div>
                    </div>
                </section>

                {/* ---------------------- main benifits ----------------------- */}

                <section>
                    <div>

                        <div className='relative  font-[Prata] '>

                            <div className='relative overflow-hidden'>
                                <video className='w-full object-cover h-screen md:h-auto' autoPlay muted loop playsInline >
                                    <source src={web} type="video/mp4" className='h-screen ' />
                                </video>
                                <div className='absolute top-0 backdrop-blur-sm bg-yellow-300/10 inset-0'></div>
                            </div>


                            <div className='absolute w-full top-8 md:top-4' >

                                <div className='flex justify-center items-center w-[90%] mx-auto mt-4 '>

                                    <div>
                                        {/* <div>
                                            <h3 className=' mb-2 text-center  text-[24px] tracking-[4px] font-kumbh   capitalize text-gray-100 font-semibold'>Elevate Your Filmmaking With Cinema Factory  </h3>
                                            <div>
                                                <img src={master} className='w-full' alt="" />
                                            </div>
                                        </div> */}

                                        <div>

                                            <div className='grid grid-cols-3 md:grid-cols-3 gap-x-3 md:gap-x-16 gap-y-16 md:gap-y-4 mt-1'>

                                                <div className='bg-black/35  shadow-gray-700  rounded-lg drop-shadow-lg py-8 px-4'>

                                                    <div className='flex flex-col  items-center '>

                                                        <div>
                                                            <img src={webser} className=' w-16 md:w-28 object-cover mb-2' alt="" />
                                                        </div>

                                                        <div>
                                                            {/* <h3 className='uppercase font-semibold text-center  text-[20px] text-white tracking-[1px]'>shoot a webseries</h3> */}
                                                            <div className='flex justify-center items-center'>
                                                                <img src={shoot} className='w-full md:w-[70%] object-cover' alt="" />
                                                            </div>
                                                        </div>

                                                        {/* <div>
                                                            <ul className='font-[roboto] text-[14px] flex flex-col gap-y-4  mt-6  ml-6 text-gray-200'>
                                                                <li className='leading-6'>
                                                                    Stand out in the booming web series market by honing your storytelling skills and gaining hands-on filmmaking experience, with opportunities for on-screen credits and collaborative projects.
                                                                    Master every production stage while managing real-world budgets and build your brand with a completed web series. Expand your creative
                                                                    boundaries and enhance your portfolio, preparing for a successful film career.
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                                    </div>
                                                </div>

                                                <div className='bg-black/35   shadow-gray-700 rounded-lg drop-shadow-lg py-8 px-4'>

                                                    <div className='flex flex-col  items-center '>

                                                        <div>
                                                            <img src={over} className='w-16 md:w-28 object-cover mb-2' alt="" />
                                                        </div>

                                                        <div>
                                                            {/* <h3 className='uppercase font-semibold text-center  text-[20px] text-white tracking-[1px]'>overseas trip</h3> */}
                                                            <div className='flex justify-center items-center'>
                                                                <img src={overseas} className='w-full md:w-[70%] object-cover' alt="" />
                                                            </div>
                                                        </div>

                                                        {/* <div>
                                                            <ul className='font-[roboto] text-[14px] flex flex-col gap-y-4  mt-6  ml-6 text-gray-200'>
                                                                <li className='leading-6'>
                                                                    Master international filmmaking by learning to travel light with essential gear, scout perfect locations considering lighting and permits,
                                                                    and adapt techniques to diverse climates. Develop data management skills and navigate shoots with cultural sensitivity and legal compliance.
                                                                    This course covers pre-production planning, effective communication on set, and safety protocols for overseas productions.
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                                    </div>
                                                </div>


                                                <div className='bg-black/35   shadow-gray-700 rounded-lg drop-shadow-lg py-8 px-4'>

                                                    <div className='flex flex-col  items-center '>

                                                        <div>
                                                            <img src={ai} className='w-16 md:w-28 object-cover mb-2' alt="" />
                                                        </div>

                                                        <div>
                                                            {/* <h3 className='uppercase font-semibold text-center  text-[20px] text-white tracking-[1px]'>overseas trip</h3> */}
                                                            <div className='flex justify-center items-center'>
                                                                <img src={aitext} className='w-full md:w-[70%] object-cover' alt="" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='bg-black/35   shadow-gray-700 rounded-lg drop-shadow-lg py-8 px-4'>

                                                    <div className='flex flex-col  items-center '>

                                                        <div>
                                                            <img src={emi} className='w-16 md:w-28 object-cover mb-2' alt="" />
                                                        </div>

                                                        <div>
                                                            {/* <h3 className='uppercase font-semibold text-center  text-[20px] text-white tracking-[1px]'>overseas trip</h3> */}
                                                            <div className='flex justify-center items-center'>
                                                                <img src={emitext} className='w-full md:w-[70%] object-cover' alt="" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='bg-black/35   shadow-gray-700 rounded-lg drop-shadow-lg py-8 px-4'>

                                                    <div className='flex flex-col  items-center '>

                                                        <div>
                                                            <img src={certicate} className='w-16 md:w-28 object-cover mb-2' alt="" />
                                                        </div>

                                                        <div>
                                                            {/* <h3 className='uppercase font-semibold text-center  text-[20px] text-white tracking-[1px]'>overseas trip</h3> */}
                                                            <div className='flex justify-center items-center'>
                                                                <img src={certicatetext} className='w-full md:w-[70%] object-cover' alt="" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='bg-black/35   shadow-gray-700 rounded-lg drop-shadow-lg py-8 px-4'>

                                                    <div className='flex flex-col  items-center '>

                                                        <div>
                                                            <img src={trainer} className='w-16 md:w-28 object-cover mb-2' alt="" />
                                                        </div>

                                                        <div>
                                                            {/* <h3 className='uppercase font-semibold text-center  text-[20px] text-white tracking-[1px]'>overseas trip</h3> */}
                                                            <div className='flex justify-center items-center'>
                                                                <img src={globaltext} className='w-full md:w-[70%] object-cover' alt="" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='bg-black/35   shadow-gray-700 rounded-lg drop-shadow-lg py-8 px-4'>

                                                    <div className='flex flex-col  items-center '>

                                                        <div>
                                                            <img src={syllabus} className='w-16 md:w-28 object-cover mb-2' alt="" />
                                                        </div>

                                                        <div>
                                                            {/* <h3 className='uppercase font-semibold text-center  text-[20px] text-white tracking-[1px]'>overseas trip</h3> */}
                                                            <div className='flex justify-center items-center'>
                                                                <img src={syllabustext} className='w-full md:w-[70%] object-cover' alt="" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='bg-black/35   shadow-gray-700 rounded-lg drop-shadow-lg py-8 px-4'>

                                                    <div className='flex flex-col  items-center '>

                                                        <div>
                                                            <img src={award} className='w-16 md:w-28 object-cover mb-2' alt="" />
                                                        </div>

                                                        <div>
                                                            {/* <h3 className='uppercase font-semibold text-center  text-[20px] text-white tracking-[1px]'>overseas trip</h3> */}
                                                            <div className='flex justify-center items-center'>
                                                                <img src={awardtext} className='w-full md:w-[70%] object-cover' alt="" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='bg-black/35   shadow-gray-700 rounded-lg drop-shadow-lg py-8 px-4'>

                                                    <div className='flex flex-col  items-center '>

                                                        <div>
                                                            <img src={internship} className='w-16 md:w-28 object-cover mb-2' alt="" />
                                                        </div>

                                                        <div>
                                                            {/* <h3 className='uppercase font-semibold text-center  text-[20px] text-white tracking-[1px]'>overseas trip</h3> */}
                                                            <div className='flex justify-center items-center'>
                                                                <img src={internshiptext} className='w-full md:w-[70%] object-cover' alt="" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* -------------------------------- mentors -------------------- */}

                <section className=' pt-10 md:pt-16 pb-10 md:pb-16 bg-gradient-to-b from-yellow-950 from-10% via-black via-40% to-black to-90% font-[parta]'>
                    <div className=' w-full px-4 md:w-[85%] mx-auto'>

                        <div className='flex justify-center items-center mb-16'>
                            {/* <h3 className='font-bold text-[4rem] text-white'>FILM MAKERS AS MENTORS</h3> */}
                            <img src={mentors} className='w-[60%] object-cover' alt="" />
                        </div>

                        <div className='flex justify-center items-center'>

                            <Mentors />

                        </div>
                    </div>
                </section>


                {/* ------------------------------ highlights ------------------- */}

                {/* <section className=' font-[roboto] '>
                    <div className=" pt-20 pb-20 inset-0 -z-10 size-full items-center px-5 py-24 [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)]" >

                        <div className=''>

                            <div className='  w-full '>

                                <div className='flex flex-col justify-center items-center  w-full'>

                                    <div className='mb-24'>
                                        <h3 className='font-bold text-[26px] text-white text-center uppercase '>We're not just different, we're better</h3>
                                    </div>


                                    <div className='grid grid-cols-3  gap-x-20 gap-y-20 w-[80%] '>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-y-4'>

                                                <div>
                                                    <img src={emi} className='w-20 object-cover ' alt="" />
                                                </div>

                                                <div>
                                                    <h3 className='text-white  text-[14px]'> EMI facilities </h3>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-y-4'>

                                                <div>
                                                    <img src={ai} className='w-20 object-cover ' alt="" />
                                                </div>

                                                <div>
                                                    <h3 className='text-white text-[14px]'>India's first AI integrated course</h3>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-y-4'>

                                                <div>
                                                    <img src={certicate} className='w-20 object-cover ' alt="" />
                                                </div>

                                                <div>
                                                    <h3 className='text-white  text-[14px]'>Prestigious Certifications</h3>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-y-4'>

                                                <div>
                                                    <img src={curreculam} className='w-20 object-cover ' alt="" />
                                                </div>

                                                <div>
                                                    <h3 className='text-white text-[14px]'>Film as curriculum Project</h3>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-y-4'>

                                                <div>
                                                    <img src={trainer} className='w-20 object-cover ' alt="" />
                                                </div>

                                                <div>
                                                    <h3 className='text-white text-[14px]'>Industry experts as your trainers</h3>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-y-4'>

                                                <div>
                                                    <img src={syllabus} className='w-20 object-cover ' alt="" />
                                                </div>

                                                <div>
                                                    <h3 className='text-white  text-[14px]'>Customized_syllabus</h3>
                                                </div>

                                            </div>
                                        </div>


                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-y-4'>

                                                <div>
                                                    <img src={connect} className='w-20 object-cover ' alt="" />
                                                </div>

                                                <div>
                                                    <h3 className='text-white  text-[14px]'>Cinema industry connectivity</h3>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-y-4'>

                                                <div>
                                                    <img src={gloabal} className='w-20 object-cover ' alt="" />
                                                </div>

                                                <div>
                                                    <h3 className='text-white  text-[14px]'>Global trainers, customized syllabus</h3>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-y-4'>

                                                <div>
                                                    <img src={award} className='w-20 object-cover ' alt="" />
                                                </div>

                                                <div>
                                                    <h3 className='text-white  text-[14px]'>Award Winning</h3>
                                                </div>

                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </section> */}




                {/* <section>
                    <div className=' font-[Prata]  '>

                        <div className='relative w-full'>

                            <div>
                                <video className='w-full object-cover ' autoPlay muted loop playsInline >
                                    <source src={video} type="video/mp4" />
                                </video>
                            </div>

                            <div className='absolute top-40  flex justify-center items-center w-full' >

                                <div className='w-[80%] mx-auto'>
                                    <div className='flex items-center '>

                                        <div className='w-[50%] mx-auto flex justify-center '>
                                            <p className='text-[8rem] text-center   text-white'>Make your Movie</p>
                                        </div>

                                        <div className=' w-[50%] mx-auto flex justify-center '>
                                            <p className='font-[roboto] bg-[#053F39]/30 shadow-sm shadow-blue-100 drop-shadow-md  px-4 leading-10  w-[95%] mx-auto text-center mt-6 text-[22px] rounded-md  text-white py-16'>To all Aspiring Directors, Cinematographers, Editor, VFX Artists, Actors, Colorist.  Start your filmmaking journey from the most prestigious institute of India.</p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </section> */}





                {/* video banner */}

                <section>
                    <div>
                        <video className='w-full object-cover ' autoPlay muted loop playsInline >
                            <source src={videoBanner} type="video/mp4" />
                        </video >
                    </div>
                </section>


                {/* ----------------------------- our mentors work --------------------- */}

                <section>
                    <div className="inset-0 -z-10 size-full bg-black bg-[linear-gradient(to_right,#060606_1px,transparent_1px),linear-gradient(to_bottom,#060606_1px,transparent_1px)] bg-[size:6rem_4rem]">
                        <div className="pt-10 md:pt-20 pb-6 md:pb-20 inset-0 bg-[radial-gradient(circle_400px_at_50%_300px,#111a30,transparent)]" >

                            <div className='w-full px-4 md:w-[80%] mx-auto'>

                                <div className='flex justify-center items-center mb-6 md:mb-20'>
                                    {/* <h3 className='font-bold text-gray-900 text-[40px] uppercase'>Mentors Works</h3> */}
                                    <img src={mentrswork} className='w-[40%] object-cover' alt="" />
                                </div>

                                <div className='px-4' >
                                    <MovieLogo />
                                </div>

                            </div>


                        </div>
                    </div>
                </section>


                {/* ----------------------- Our Knowledge partners -------------------- */}

                <section className=' '>
                    <div className="pt-10 md:pt-20 pb-10 md:pb-20 top-0 z-[-2] w-screen bg-white bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]" >
                        <div className=' w-full px-4 md:w-[80%] mx-auto'>

                            <div className='flex justify-center'>
                                {/* <h3 className='uppercase text-[14px] sm:text-[20px] md:text-[24px] tracking-[1px] md:tracking-[2px] font-semibold text-black text-center '> Our knowledge Partners</h3> */}
                                <img src={our} className='w-[80%]  md:w-[40%] object-cover' alt="" />
                            </div>

                            <div className='mt-5 md:mt-8' data-aos='fade-up'>

                                <div>
                                    <Clients />
                                </div>

                            </div>
                        </div>
                    </div>
                </section >


                {/* ------------------------- Infrastructure ------------------------ */}

                < section className=' pt-10 md:pt-20 pb-10 md:pb-20 bg-white' >
                    <div className='w-[80%] mx-auto'>

                        <div className='flex justify-center items-center mb-6 md:mb-10'>
                            {/* <h3 className='font-semibold text-[24px]'>Infrastructure</h3> */}
                            <img src={infras} className=' w-[70%] md:w-[40%] object-cover' alt="" />
                        </div>


                        <div>
                            <Infra />
                        </div>


                    </div>
                </section >

                {/* --------------------------------- faq ------------------------------ */}


                <section className=' pt-10 md:pt-20 pb-10 md:pb-20 bg-[#1f2228]  font-[parta]'>
                    <div className=' w-full px-4 md:w-[80%] mx-auto'>

                        {/* <div className='flex justify-center items-center'>
                            <img src={question} className='w-[60%] object-cover' alt="" />
                        </div> */}

                        <div className='flex flex-col md:flex-row justify-center items-start gap-y-10'>

                            <div className=' w-full md:w-[30%]'>

                                <div className=' px-2 md:px-6 flex flex-col gap-y-2 md:gap-y-12 items-center font-[poppins]'>
                                    <h3 className='font-bold text-[35px] md:text-[3.5rem] text-center flex flex-wrap items-center justify-center md:flex-col gap-x-3   md:gap-y-2 md:items-start text-white font-kumbh'>
                                        <span>Frequently</span>
                                        <span>Asked</span>
                                        <span className='text-[#ff0000]'>Question</span>
                                    </h3>

                                    <div className='font-kumbh w-full flex flex-col justify-center items-center md:items-start'>
                                        <p className='font-semibold text-start text-[22px] md:text-[20px] text-gray-200'>Still more doubts?</p>
                                        <div className='flex justify-center items-center mt-6 md:mt-10 w-full'>
                                            <button className='hover:scale-105 duration-300 w-full bg-gradient-to-r from-blue-700 to-[#ff0000] px-6 py-2 rounded-md font-semibold text-white uppercase tracking-[1px]'>Contact Us</button>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className=' w-full md:w-[80%]'>
                                <div className='w-full flex justify-center items-center  '>
                                    <div className='w-full md:px-10'>
                                        <FAQ />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* ----------------------- mentors work -------------------------- */}

                {/* <section className='bg-black pt-20 pb-20 font-[parta]'>
                    <div className='w-full mx-auto'>


                        <div className='mb-16'>
                            <div className='flex justify-center items-center'>
                                <img src={work} className='w-[90%] object-cover' alt="" />
                            </div>
                        </div>

                        <div>
                            <div>

                                <Works />

                            </div>
                        </div>

                    </div>
                </section> */}



                <section className=' bg-gradient-to-r from-orange-600 to-indigo-400  font-[Prata] '>
                    <div className=" top-0 z-[-2]  bg-neutral-950 bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]" >

                        {/* <div className='w-[90%] mx-auto '>

                            <div className='flex justify-center items-center '>
                                <div>

                                    <div className="w-max" data-aos='fade-up' data-aos-duration="1000">


                                        {text.split('').map((char, index) => (
                                            <span
                                                key={index}
                                                className="animate-wave text-center text-[20px] sm:text-[24px] md:text-[4rem] font-[poppins] font-bold text-red-600 uppercase"
                                                style={{ animationDelay: `${index * 0.1}s` }}
                                            >
                                                {char === ' ' ? '\u00A0' : char}
                                            </span>
                                        ))}


                                    </div>

                                   

                                    <div className='flex justify-center items-center mt-8'>
                                        <button className='border-2 border-white uppercase  text-white tracking-[1px] px-10 py-3 font-[roboto] hover:scale-105 duration-500 font-semibold rounded-lg'> Apply Now </button>
                                    </div>
                                </div>
                            </div>
                            
                        </div> */}

                        <div className='relative'>

                            <div>
                                <img src={stop} className='w-full object-cover ' alt="" />
                            </div>

                            <div className='absolute bottom-2 md:bottom-16 w-full flex justify-center items-center  '>
                                <Link to='contact' onClick={topPage}>
                                    <button
                                        class="hover:animate-bounce group font-[poppins] relative font-semibold inline-flex h-7 md:h-12 items-center justify-center overflow-hidden rounded-md bg-[#ff0000]/30 px-4 md:px-10  border border-white uppercase  text-white"
                                    >
                                        <span
                                            class="absolute md:h-56 w-full rounded-full bg-[#ff0000] group-hover:scale-105 transition-all duration-300 group-hover:size-0"
                                        ></span>
                                        <span class="relative text-[10px] md:text-[14px]  md:tracking-[1px]">Get's Start</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        
                    </div>

                </section >

                {/* ----------------------- contact ----------------- */}


                {/* <section className='pt-20 pb-20 bg-gray-950 font-[Aladin]'>

                    <div className='w-[80%] mx-auto'>

                        <div>
                            <h3 className='font-semibold text-[20px] sm:text-[24px] md:text-[4rem] text-center md:tracking-[2px] text-gray-50' data-aos='fade-up'>Learn, Explore, Grow with Us!</h3>
                        </div>

                        <div className='flex items-start justify-between mt-16'>


                            <div className='w-[50%]'>

                                <div>
                                    <img src={banner} className='w-[80%] object-cover ' alt="" />
                                </div>

                            </div>

                            <div className='w-[50%]'>


                                <div className='px-14'>
                                    <ContactForm />
                                </div>

                            </div>
                        </div>

                    </div>

                </section> */}


            </div >

        </>
    )
}

export default Home

