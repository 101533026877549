import React from 'react'
import Slider from "react-slick";
// import DirectionFAQ from '../../components/Direction_FAQ';
import VfxFAQ from '../../components/VfxFAQ';
import '../text.css'

import { FaArrowRight } from "react-icons/fa6";


import banner from '../../images/course/banner/pattern.jpg'
import frame from '../../images/course/vfx/vfx.png'
import aravind from '../../images/mentors/Aravind.jpg'
import muniraj from '../../images/mentors/muniraj.jpg'
import light from '../../images/course/vfx/vfx_2.png'
import who from '../../images/course/vfx/vfx_3.png'

import orange from '../../images/banner/orange.jpg'

import posterOne from '../../images/poster/mv1.jpg'
import posterTwo from '../../images/poster/mv2.jpg'
import posterThree from '../../images/poster/mv3.png'
import posterFour from '../../images/poster/mv4.png'

import aravindOne from '../../images/poster/aravind/1.png'
import aravindTwo from '../../images/poster/aravind/2.png'
import aravindThree from '../../images/poster/aravind/3.png'
import aravindFour from '../../images/poster/aravind/4.png'
import aravindFive from '../../images/poster/aravind/5.png'
import aravindSix from '../../images/poster/aravind/6.png'
import aravindSeven from '../../images/poster/aravind/7.png'

import munirajOne from '../../images/poster/muniraj/1.png'
import munirajTwo from '../../images/poster/muniraj/2.png'
import munirajThree from '../../images/poster/muniraj/3.png'
import munirajFour from '../../images/poster/muniraj/4.png'
import munirajFive from '../../images/poster/muniraj/5.png'




//icons
import { RiWhatsappLine } from "react-icons/ri";

import { TbCreativeCommonsBy } from "react-icons/tb";
import { PiFilmSlateDuotone } from "react-icons/pi";
import { MdLeaderboard } from "react-icons/md";
import { MdOutlineSystemSecurityUpdateGood } from "react-icons/md";
import { MdOutlineSyncProblem } from "react-icons/md";
import { RiIndeterminateCircleLine } from "react-icons/ri";


import iconOne from '../../images/course/vfx/1.png'
import iconTwo from '../../images/course/vfx/2.png'
import iconThree from '../../images/course/vfx/3.png'
import iconFour from '../../images/course/vfx/4.png'
import iconFive from '../../images/course/vfx/5.png'
import iconSix from '../../images/course/vfx/6.png'
import iconSeven from '../../images/course/vfx/7.png'
import iconEight from '../../images/course/vfx/8.png'






const Vfx = () => {

    const settings = {
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
        },
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "30px",
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "15px",
                }
            }
        ]
    };


    return (
        <>

            <div className='font-kumbh overflow-hidden '>

                <div className='  '>
                    <img src={banner} className=' blur-[2px] w-full fixed top-0 object-cover h-screen -z-30' alt="" />
                </div>


                <section>
                    <div className=''>

                        <div>
                            <img src={frame} className='w-full object-cover' alt="" />
                        </div>

                    </div>
                </section>


                {/* -------------- course highligts ----------------- */}

                <section className='bg-gray-950'>

                    <div className='relative'>

                        <div className='relative'>
                            <img src={light} className='w-full object-cover h-screen md:h-auto' alt="" />
                            <div className='w-full backdrop-blur-sm bg-[#ffff00]/15 inset-0 absolute top-0'></div>
                        </div>

                        <div className='absolute w-full top-0'>

                            <div className='w-full px-4 md:w-[90%] mx-auto mt-10 md:mt-16'>

                                <div className='mb-8 flex flex-col items-center'>
                                    <h3 className='font-bold text-[20px] md:text-[40px] text-white text-center uppercase tracking-[2px]'>Course highlights </h3>
                                    {/* <div className='border-2  border-orange-500 rounded-[80%] w-[30%] '></div> */}
                                </div>

                                <div className='flex justify-center items-center md:mt-12'>

                                    <div className='grid grid-cols-2 md:grid-cols-4 gap-x-6 md:gap-x-14 gap-y-4 md:gap-y-14  text-[12px] md:text-[14px]'>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconOne} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Modern VFX syllabus</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconTwo} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>AI-integrated VFX course.</h3>
                                            </div>
                                        </div>


                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconThree} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Film academy VFX training</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconFour} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Master filmmaking and storytelling.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconFive} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Hands-on training with industry tools.</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full  rounded-md'>

                                                <div>
                                                    <img src={iconSix} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Real-world project experience.</h3>
                                            </div>
                                        </div>


                                        <div className='hidden md:flex'>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconSeven} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Industry collaboration.</h3>
                                            </div>
                                        </div>

                                        <div className='hidden md:flex'>
                                            <div className='flex flex-col gap-y-1 md:gap-y-3 items-center bg-black/20 px-4 py-6 h-full w-full rounded-md'>

                                                <div>
                                                    <img src={iconEight} className='w-16 md:w-28 object-cover' alt="" />
                                                </div>
                                                <h3 className='text-white font-semibold text-center'>Holistic VFX career training.</h3>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                                {/* <div class=" p-4">

                                    <div class="flex flex-col grid-cols-9 p-2 mx-auto md:grid">
                                        <div class="flex md:contents flex-row-reverse">

                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Hone skills in editing and other crafts</h3>

                                            </div>

                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 rounded-t-full bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center  ">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>1</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex md:contents">
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>2</h3>
                                                </div>
                                            </div>
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-white drop-shadow-md rounded-xl col-start-6 col-end-10 mr-auto">
                                                <h3 class="text-[20px]  font-semibold  ">Integrate AI in screenplay & direction</h3>

                                            </div>
                                        </div>

                                        <div class="flex md:contents flex-row-reverse">
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-white drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  ">Perfect the art of staging characters</h3>

                                            </div>
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center justify-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300  bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>3</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex md:contents">
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col justify-center items-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300 bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>4</h3>
                                                </div>
                                            </div>
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-6 col-end-10 mr-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Master the language of cinema</h3>

                                            </div>
                                        </div>


                                        <div class="flex md:contents flex-row-reverse">
                                            <div class="relative px-16 py-10 my-6 text-gray-800 border border-red-400 bg-yellow-400/65 drop-shadow-md rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                                                <h3 class="text-[20px]  font-semibold  text-white">Learn techniques for filming dialogues</h3>
                                            </div>
                                            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto flex flex-col items-center justify-center">
                                                <div class="flex items-center justify-center w-6 h-full">
                                                    <div class="w-2 h-full bg-indigo-300  bg-gradient-to-b from-[#ff0000] to-orange-400"></div>
                                                </div>
                                                <div class="absolute w-12 h-12 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2 flex justify-center items-center">
                                                    <h3 className='font-bold text-[14px] font-[poppins]'>5</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}

                            </div>

                        </div>
                    </div>
                </section>



                {/* -------------- Syllabus ----------------- */}

                <section className='border-t-4 border-orange-500 pt-16 pb-10 md:pt-20 md:pb-20 bg-gray-950 -mt-6'>
                    <div className='w-full px-4 md:w-[85%] mx-auto'>

                        <div className='flex flex-col gap-y-2 justify-center items-center mb-6 md:mb-16'>
                            <h3 className='font-bold text-center text-[24px] md:text-[40px] text-white font-kumbh uppercase'>1 Year Diploma Course  </h3>
                            <p className='font-semibold text-[18px] md:text-[24px] text-[#ff0000]  font-[roboto] uppercase tracking-[1px] '> in VFX & CG</p>
                        </div>

                        <div className='flex flex-col md:flex-row items-center gap-y-8'>

                            <div className='w-full md:w-[50%] md:px-5 mx-auto'>
                                <div>
                                    <div class="border border-gray-600 overflow-y-auto h-[25rem] scrollbar-style-one px-2 md:px-6 py-4 rounded-md overscroll-y-contain	">

                                        <div className=''>
                                            <h2 className='text-[18px] md:text-[26px] font-semibold mb-6 text-white'>Month 1-2: Introduction to VFX & Adobe Tools</h2>

                                            <div className='flex flex-col gap-y-4 md:gap-y-6'>

                                                <div className='flex flex-col gap-y-3'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> History and Introduction of VFX</h3>

                                                    <ul className=' text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>Introduction to the evolution and importance of VFX, covering milestones and pioneers in the field.</li>
                                                        <li>Exploration of VFX technologies from practical effects to CGI, with a focus oncontemporary applications.</li>
                                                        <li>Discussion on ethics, emerging trends, and the role of VFX in shaping the future of media.</li>

                                                    </ul>
                                                </div>


                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Introduction to Adobe Creative Cloud </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>Overview of Adobe Photoshop, Illustrator, and After Effects</li>
                                                        <li>Basic Tools and Interface Navigation</li>
                                                    </ul>
                                                </div>


                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>    VFX Techniques in Adobe After Effects</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>Rotoscoping and Masking</li>
                                                        <li>Chroma Keying (Green Screen)</li>
                                                        <li>Tracking and Stabilization</li>

                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>    Rotoscopy Fundamentals</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>Understanding Rotoscopy Techniques</li>
                                                        <li>Rotoscoping Softwares: Silhouette, Mocha Pro</li>

                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>    Matte Painting Techniques</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>Digital Painting Fundamentals</li>
                                                        <li>Matte Painting Workflow</li>
                                                        <li>Software: Adobe Photoshop</li>

                                                    </ul>
                                                </div>


                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Introduction to Matchmove  </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>  Matchmove Fundamentals</li>
                                                        <li> Tracking Techniques </li>
                                                        <li> Software: PFTrack, Boujou </li>
                                                    </ul>
                                                </div>


                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Motion Graphics</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> Motion Graphics Principles</li>
                                                        <li>Animation Techniques in After Effects </li>
                                                        <li>Software: Adobe After Effects </li>
                                                    </ul>
                                                </div>


                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Introduction to Blender</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>Blender Interface and Navigation </li>
                                                        <li>Modeling Tools and Techniques </li>
                                                    </ul>
                                                </div>


                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Texturing and Shading in Blender</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li> UV Unwrapping and Texture Painting</li>
                                                        <li> Shading and Material Creation</li>

                                                    </ul>
                                                </div>

                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'> Lighting, Rigging, and Animation in Blender</h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>Basics of Lighting and Rendering </li>
                                                        <li> Rigging Characters and Objects</li>
                                                        <li> Animation Principles and Techniques</li>

                                                    </ul>
                                                </div>


                                                <div className='flex flex-col gap-y-2'>
                                                    <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Introduction to Unreal Engine </h3>

                                                    <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                        <li>Familiarization with the interface, navigation, and basic tools. </li>
                                                        <li>Techniques for building environments, setting up lighting, and applying materials. </li>
                                                        <li>Basics of animating objects and cameras, and creating cinematic sequences. </li>
                                                        <li>Understanding the fundamentals of real-time rendering for film production. </li>

                                                    </ul>
                                                </div>


                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>


                            <div className='w-full md:w-[50%] md:px-5 mx-auto'>

                                <div>
                                    <div class="border border-gray-600 overflow-y-auto h-[25rem] scrollbar-style-one px-2 md:px-6 py-4 rounded-md overscroll-y-contain	">

                                        <div className='flex flex-col gap-y-6'>

                                            <div className='flex flex-col gap-y-2'>
                                                <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Introduction to Autodesk Maya </h3>

                                                <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                    <li>  Maya Interface and Navigation  </li>
                                                    <li>  Polygonal Modeling Techniques </li>

                                                </ul>
                                            </div>

                                            <div className='flex flex-col gap-y-2'>
                                                <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Advanced Modeling and Texturing in Maya </h3>

                                                <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                    <li>  NURBS Modeling and Organic Modeling  </li>
                                                    <li>  Texture Mapping and UV Layouts </li>

                                                </ul>
                                            </div>

                                            <div className='flex flex-col gap-y-2'>
                                                <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Rigging and Animation in Maya</h3>

                                                <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                    <li>  Character Rigging Techniques </li>
                                                    <li>  Keyframe Animation and Animation Layers </li>
                                                   
                                                </ul>
                                            </div>

                                            <div className='flex flex-col gap-y-2'>
                                                <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Lighting and Rendering Techniques</h3>

                                                <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                    <li>  Types of Lights and Lighting Setups </li>
                                                    <li>   Introduction to Render Engines (Arnold, V-Ray)</li>
                                                   
                                                
                                                </ul>
                                            </div>

                                            <div className='flex flex-col gap-y-2'>
                                                <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Rendering Optimization</h3>

                                                <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                    <li> Render Settings Optimization</li>
                                                    <li>Render Passes and AOVs </li>
                                                
                                                </ul>
                                            </div>

                                            <div className='flex flex-col gap-y-2'>
                                                <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Introduction to Nuke </h3>

                                                <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                    <li> Nuke Interface and Node-based Workflow</li>
                                                    <li> Basic Compositing Techniques</li>
                                                  
                                                </ul>
                                            </div>

                                            <div className='flex flex-col gap-y-2'>
                                                <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>  Advanced Compositing Techniques in Nuke </h3>

                                                <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                    <li> Keying and Rotoscoping</li>
                                                    <li> 3D Compositing and Tracking</li>
                                                  
                                                </ul>
                                            </div>

                                            <div className='flex flex-col gap-y-2'>
                                                <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>   AI Tools in VFX</h3>

                                                <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                    <li>Overview of AI Integration in VFX</li>
                                                    <li> Use Cases and Applications</li>
                                                  
                                                </ul>
                                            </div>

                                            <div className='flex flex-col gap-y-2'>
                                                <h3 className='font-semibold text-[16px] md:text-[20px] text-gray-200'>   Ethical Use of AI in VFX</h3>

                                                <ul className='text-[12px] md:text-[14px] font-[roboto] flex flex-col gap-y-1 text-gray-400'>
                                                    <li>Understanding Bias and Fairness</li>
                                                    <li>Ethical Considerations in AI-Generated Content</li>
                                                    
                                                </ul>
                                            </div>
                                            
                                        
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </section>




                {/* ------------------ Mentors ------------------ */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20'>
                    <div className='w-full px-4 md:w-[80%] mx-auto font-kumbh'>

                        <div className='flex items-center justify-center mb-6 md:mb-16'>
                            <h3 className='font-bold text-white text-[20px] md:text-[40px] text-center uppercase tracking-[2px]'>Film Makers As Mentors</h3>
                        </div>

                        <div className='flex justify-center items-center'>

                            <div className='grid grid-cols-1 md:grid-cols-2 gap-y-6 md:gap-y-16 gap-x-20 '>

                                <div className='flex flex-col  items-center justify-center'>

                                    <div className='flex justify-center items-center'>
                                        <img src={aravind} className='w-[80%] rounded-md  object-cover' alt="" />
                                    </div>

                                    <div className='flex flex-col gap-y-5 items-center justify-center mt-5'>
                                        {/* <div className='flex flex-col gapy-2 items-center '>
                                            <h3 className='font-bold text-[28px] text-white tracking-[2px]'>Aravind Naga</h3>
                                            <span className='text-orange-500 text-[16px]'>HOD | Visual Effects</span>
                                        </div> */}
                                        <div className=' mx-auto '>
                                            <p className='text-[12px] md:text-[14px] text-gray-200 text-center font-[roboto]'>
                                                Aravind Naga, an award-winning VFX and Post Production Supervisor, excels in film, advertising, and digital content creation, passionately mentoring future VFX professionals.
                                            </p>
                                        </div>

                                        <div className='flex justify-center items-center font-[poppins] text-[12px] md:text-[14px] font-semibold'>
                                            <button className="group relative inline-flex h-11 items-center justify-center overflow-hidden rounded-md border border-gray-300 bg-[#ff0000] px-6 md:px-8 font-medium text-neutral-200 duration-500">
                                                <div className="uppercase translate-x-0 opacity-100 transition group-hover:translate-x-[-150%] group-hover:opacity-0 font-semibold font-[poppins] ">
                                                    Explore More
                                                </div>
                                                <div className="absolute translate-x-[150%] opacity-0 transition group-hover:translate-x-0 group-hover:opacity-100">
                                                    <FaArrowRight className='font-semibold text-[18px] text-white' />
                                                </div>
                                            </button>
                                        </div>

                                    </div>
                                </div>


                                <div className='flex flex-col  items-center justify-center'>

                                    <div className='flex justify-center items-center'>
                                        <img src={muniraj} className=' w-[80%] rounded-md object-cover' alt="" />
                                    </div>

                                    <div className='flex flex-col gap-y-5 items-center justify-center mt-5'>
                                        {/* <div className='flex flex-col gapy-2 items-center '>
                                            <h3 className='font-bold text-[28px] text-white tracking-[2px]'>Muniraj</h3>
                                            <span className='text-orange-500 text-[16px]'>Creative Technologist</span>
                                        </div> */}
                                        <div className=' '>
                                            <p className='text-[12px] md:text-[14px] text-gray-200 text-center font-[roboto]'>
                                                Muniraj, a creative technologist with 12+ years of experience, excels in film production problem-solving, contributing to notable Bollywood and Kollywood projects.
                                            </p>
                                        </div>

                                        <div className='flex justify-center items-center font-[poppins] text-[12px] md:text-[14px] font-semibold'>
                                            <button className="group relative inline-flex h-11 items-center justify-center overflow-hidden rounded-md border border-gray-300 bg-[#ff0000] px-6 md:px-8 font-medium text-neutral-200 duration-500">
                                                <div className="uppercase translate-x-0 opacity-100 transition group-hover:translate-x-[-150%] group-hover:opacity-0 font-semibold font-[poppins] ">
                                                    Explore More
                                                </div>
                                                <div className="absolute translate-x-[150%] opacity-0 transition group-hover:translate-x-0 group-hover:opacity-100">
                                                    <FaArrowRight className='font-semibold text-[18px] text-white' />
                                                </div>
                                            </button>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </section>


                {/* -------------- Our Mentors have Worked In ------------------------ */}

                <section className='bg-black overflow-hidden flex justify-center items-center pt-14 pb-10 '>

                    <div className='w-full mx-auto'>

                        <div className='flex justify-center items-center mb-12'>
                            <h3 className='font-bold uppercase text-[20px] md:text-[28px] text-white'>Our Mentor's Work</h3>
                        </div>

                        <div>

                            <div className="slider-container">
                                <Slider {...settings} className="">

                                    <div className='px-2'>
                                        <div>
                                            <img src={aravindOne} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={aravindTwo} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={aravindThree} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={aravindFour} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={aravindFive} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={aravindSix} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={aravindSeven} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>


                                    <div className='px-2'>
                                        <div>
                                            <img src={munirajOne} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={munirajTwo} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={munirajThree} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={munirajFour} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div>
                                            <img src={munirajFive} className='w-full object-cover' alt="" />
                                        </div>
                                    </div>




                                </Slider>
                            </div>

                        </div>
                    </div>
                </section>


                {/* ----------------------- who can apply -------------------------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-black'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex justify-center items-center mb-8 md:mb-14'>
                            <h3 className='font-bold text-[20px] text-center md:text-[35px] text-white '>Who can apply for the course? </h3>
                        </div>

                        <div className='flex flex-col md:flex-row justify-center items-center gap-y-8'>

                            <div className='w-full md:w-[40%] mx-auto'>

                                <div className='flex justify-center items-center'>
                                    <img src={who} className='w-[80%] object-cover rounded-md shadow-md drop-shadow-lg' alt="" />
                                </div>
                            </div>


                            <div className='w-full md:w-[60%] mx-auto md:px-16'>
                                <div className='flex justify-center items-center w-full'>

                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6 md:gap-y-20 font-semibold w-full'>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <TbCreativeCommonsBy className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Creative Thinker</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <PiFilmSlateDuotone className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Film Enthusiast</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <MdLeaderboard className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> A leader</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>

                                                    <MdOutlineSystemSecurityUpdateGood className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px] ' > Good Communicator</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <MdOutlineSyncProblem className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'>  Problem Solver</h3>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex items-center gap-x-3 bg-white w-full rounded-md py-4 px-6'>
                                                <div className='size-12 rounded-full  flex justify-center items-center bg-gray-200 '>
                                                    <RiIndeterminateCircleLine className='text-[28px] text-[#ff0000]' />
                                                </div>
                                                <h3 className='font-semibold text-[16px]'> Determined Person</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* ------------------------------ FAQ ----------------------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-[#1f2228]  font-[parta]'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>


                        <div className='flex flex-col gap-y-10 md:flex-row justify-center items-start'>

                            <div className='w-full md:w-[30%]'>

                                <div className=' px-2 md:px-6 flex flex-col gap-y-2 md:gap-y-12 items-center font-[poppins]'>
                                    <h3 className='font-bold text-[35px] md:text-[3.5rem] text-center flex flex-wrap items-center justify-center md:flex-col gap-x-3   md:gap-y-2 md:items-start text-white font-kumbh'>
                                        <span>Frequently</span>
                                        <span>Asked</span>
                                        <span className='text-[#ff0000]'>Question</span>
                                    </h3>

                                    <div className='font-kumbh w-full flex flex-col justify-center items-center md:items-start'>
                                        <p className='font-semibold text-start text-[22px] md:text-[20px] text-gray-200'>Still more doubts?</p>
                                        <div className='flex justify-center items-center mt-6 md:mt-10 w-full'>
                                            <button className='hover:scale-105 duration-300 w-full bg-gradient-to-r from-blue-700 to-[#ff0000] px-6 py-2 rounded-md font-semibold text-white uppercase tracking-[1px]'>Contact Us</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='w-full md:w-[80%]'>
                                <div className='w-full flex justify-center items-center  '>
                                    <div className='w-full md:px-10'>
                                        <VfxFAQ />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* -------------------- start banner  ------------------ */}

                {/* <section className='pt-20 pb-20 bg-gray-950'>
                    <div className='w-[80%] mx-auto'>

                        <div className='flex justify-center items-center'>
                            <img src={orange} className='w-[80%] object-cover rounded-md drop-shadow-md shadow-md' alt="" />
                        </div>

                    </div>
                </section> */}



                {/* --------------------- how to appply  -------------------*/}
                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-gray-950'>

                    <div className='px-4 w-full md:w-[80%] mx-auto'>

                        <div>
                            <h3 className='text-white font-bold text-[20px] md:text-[30px] mb-3 md:mb-5 uppercase'>How to apply?</h3>

                            <div className='flex flex-col gap-y-2 text-[14px] text-gray-200'>

                                <p className='font-[roboto] text-[12px] md:text-[14px]'>To join Cinema Factory Film Academy, first, fill out the enquiry form or call us. Next, speak with our student counselor to finalize your chosen craft. Then, complete the application form and attend an interview. Upon acceptance, you'll receive an acceptance letter.</p>
                            </div>

                            <div className='flex items-center gap-x-4 md:gap-x-10 mt-4 md:mt-10 font-[poppins] text-[12px] md:text-[14px]'>
                                <div>

                                    <button
                                        class="uppercase  group relative inline-flex h-10 md:h-12 items-center justify-center overflow-hidden rounded-md bg-[#ff0000] border border-white px-6 md:px-10 font-medium text-neutral-200 duration-500"
                                    >
                                        <div class="translate-y-0 transition group-hover:-translate-y-[150%]">
                                            Apply now
                                        </div>
                                        <div class="absolute translate-y-[150%] transition group-hover:translate-y-0">
                                            Apply Now
                                        </div>
                                    </button>
                                </div>

                                <div>
                                    <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="">
                                        <button className="bg-transparent border border-white text-white shadow-md drop-shadow-md h-10 md:h-12 pl-3 rounded-md flex items-center gap-x-3 font-[poppins] text-[12px] md:text-[14px] hover:scale-105 duration-500">
                                            Download syllabus
                                            <div className='px-3 bg-green-600 rounded-r-md'>
                                                <RiWhatsappLine className='text-white h-10 md:h-12 text-[22px] font-bold' />
                                            </div>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div>

        </>
    )
}

export default Vfx



