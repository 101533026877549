import React, { useState } from 'react';

const MultiForm = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        step1: '',
        step2: '',
        step3: '',
        step4: '',
        step5: '',
    });

    const nextStep = () => {
        if (step < 5) setStep(step + 1);
    };

    const prevStep = () => {
        if (step > 1) setStep(step - 1);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        // Submit form data
    };

    return (

        <>
            <div>
                <div className="w-full md:w-[60%] px-4 md:px-14 py-4 md:py-6 mx-auto md:mt-10  ">
                    {/* Timeline */}
                    <div className="flex items-center justify-between mb-6 md:mb-14">
                        {[1, 2, 3, 4, 5].map((item, index) => (
                            <div key={index} className="flex-1 relative">

                                <div
                                    className={`z-30 flex items-center justify-center w-10 h-10 mx-auto rounded-full transition duration-500 ${step >= item ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-600'
                                        }`}
                                >
                                    {item}
                                </div>

                                {index < 4 && (
                                    <div
                                        className={`absolute left-16 md:left-40 top-5 transform -translate-x-1/2 h-1 -z-10 transition-all duration-500 ${step > item ? 'bg-blue-600' : 'bg-gray-300'
                                            }`}
                                        style={{ width: 'calc(125% - 40px)' }}
                                    ></div>
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Form */}
                    <div className='w-full '>
                        <form onSubmit={handleSubmit}>
                            {step === 1 && (
                                <div>
                                    <div className=''>

                                        <div class="mb-5">
                                            <label for="name" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Full Name
                                            </label>
                                            <input type="text" name="name" id="name" placeholder="Full Name"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="phone" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Phone Number
                                            </label>
                                            <input type="text" name="phone" id="phone" placeholder="Enter your phone number"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="email" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Email Address
                                            </label>
                                            <input type="email" name="email" id="email" placeholder="Enter your email"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="-mx-3 flex flex-wrap">
                                            <div class="w-full px-3 sm:w-1/2">
                                                <div class="mb-5">
                                                    <label for="date" class="mb-3 block text-base font-medium text-[#07074D]">
                                                        Date
                                                    </label>
                                                    <input type="date" name="date" id="date"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                            </div>
                                            <div class="w-full px-3 sm:w-1/2">
                                                <div class="mb-5">
                                                    <label for="time" class="mb-3 block text-base font-medium text-[#07074D]">
                                                        Time
                                                    </label>
                                                    <input type="time" name="time" id="time"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                    <div className='flex justify-end'>
                                        <button
                                            type="button"
                                            onClick={nextStep}
                                            className="bg-blue-600 text-white px-4 py-2 rounded"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            )}

                            {step === 2 && (
                                <div>
                                    <div className=''>

                                        <div class="mb-5">
                                            <label for="name" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Full Name
                                            </label>
                                            <input type="text" name="name" id="name" placeholder="Full Name"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="phone" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Phone Number
                                            </label>
                                            <input type="text" name="phone" id="phone" placeholder="Enter your phone number"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="email" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Email Address
                                            </label>
                                            <input type="email" name="email" id="email" placeholder="Enter your email"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="-mx-3 flex flex-wrap">
                                            <div class="w-full px-3 sm:w-1/2">
                                                <div class="mb-5">
                                                    <label for="date" class="mb-3 block text-base font-medium text-[#07074D]">
                                                        Date
                                                    </label>
                                                    <input type="date" name="date" id="date"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                            </div>
                                            <div class="w-full px-3 sm:w-1/2">
                                                <div class="mb-5">
                                                    <label for="time" class="mb-3 block text-base font-medium text-[#07074D]">
                                                        Time
                                                    </label>
                                                    <input type="time" name="time" id="time"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                            </div>
                                        </div>




                                    </div>

                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            onClick={prevStep}
                                            className="bg-gray-500 text-white px-4 py-2 rounded"
                                        >
                                            Back
                                        </button>
                                        <button
                                            type="button"
                                            onClick={nextStep}
                                            className="bg-blue-600 text-white px-4 py-2 rounded"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            )}

                            {step === 3 && (
                                <div>
                                    <div className=''>

                                        <div class="mb-5">
                                            <label for="name" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Full Name
                                            </label>
                                            <input type="text" name="name" id="name" placeholder="Full Name"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="phone" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Phone Number
                                            </label>
                                            <input type="text" name="phone" id="phone" placeholder="Enter your phone number"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="email" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Email Address
                                            </label>
                                            <input type="email" name="email" id="email" placeholder="Enter your email"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="-mx-3 flex flex-wrap">
                                            <div class="w-full px-3 sm:w-1/2">
                                                <div class="mb-5">
                                                    <label for="date" class="mb-3 block text-base font-medium text-[#07074D]">
                                                        Date
                                                    </label>
                                                    <input type="date" name="date" id="date"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                            </div>
                                            <div class="w-full px-3 sm:w-1/2">
                                                <div class="mb-5">
                                                    <label for="time" class="mb-3 block text-base font-medium text-[#07074D]">
                                                        Time
                                                    </label>
                                                    <input type="time" name="time" id="time"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                    <div className="flex justify-between">
                                        <button
                                            type="button"
                                            onClick={prevStep}
                                            className="bg-gray-500 text-white px-4 py-2 rounded"
                                        >
                                            Back
                                        </button>
                                        <button
                                            type="button"
                                            onClick={nextStep}
                                            className="bg-blue-600 text-white px-4 py-2 rounded"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            )}

                            {step === 4 && (
                                <div>
                                    <div className=''>

                                        <div class="mb-5">
                                            <label for="name" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Full Name
                                            </label>
                                            <input type="text" name="name" id="name" placeholder="Full Name"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="phone" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Phone Number
                                            </label>
                                            <input type="text" name="phone" id="phone" placeholder="Enter your phone number"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="email" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Email Address
                                            </label>
                                            <input type="email" name="email" id="email" placeholder="Enter your email"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="-mx-3 flex flex-wrap">
                                            <div class="w-full px-3 sm:w-1/2">
                                                <div class="mb-5">
                                                    <label for="date" class="mb-3 block text-base font-medium text-[#07074D]">
                                                        Date
                                                    </label>
                                                    <input type="date" name="date" id="date"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                            </div>
                                            <div class="w-full px-3 sm:w-1/2">
                                                <div class="mb-5">
                                                    <label for="time" class="mb-3 block text-base font-medium text-[#07074D]">
                                                        Time
                                                    </label>
                                                    <input type="time" name="time" id="time"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                    <div className="flex justify-between">
                                        <button
                                            type="button"
                                            onClick={prevStep}
                                            className="bg-gray-500 text-white px-4 py-2 rounded"
                                        >
                                            Back
                                        </button>
                                        <button
                                            type="button"
                                            onClick={nextStep}
                                            className="bg-blue-600 text-white px-4 py-2 rounded"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            )}

                            {step === 5 && (
                                <div>
                                    <div className=''>

                                        <div class="mb-5">
                                            <label for="name" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Full Name
                                            </label>
                                            <input type="text" name="name" id="name" placeholder="Full Name"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="phone" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Phone Number
                                            </label>
                                            <input type="text" name="phone" id="phone" placeholder="Enter your phone number"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="email" class="mb-3 block text-base font-medium text-[#07074D]">
                                                Email Address
                                            </label>
                                            <input type="email" name="email" id="email" placeholder="Enter your email"
                                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="-mx-3 flex flex-wrap">
                                            <div class="w-full px-3 sm:w-1/2">
                                                <div class="mb-5">
                                                    <label for="date" class="mb-3 block text-base font-medium text-[#07074D]">
                                                        Date
                                                    </label>
                                                    <input type="date" name="date" id="date"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                            </div>
                                            <div class="w-full px-3 sm:w-1/2">
                                                <div class="mb-5">
                                                    <label for="time" class="mb-3 block text-base font-medium text-[#07074D]">
                                                        Time
                                                    </label>
                                                    <input type="time" name="time" id="time"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                    <div className="flex justify-between">
                                        <button
                                            type="button"
                                            onClick={prevStep}
                                            className="bg-gray-500 text-white px-4 py-2 rounded"
                                        >
                                            Back
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-green-600 text-white px-4 py-2 rounded"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>

        </>

    );
};

export default MultiForm;
