import React from 'react'

// import logo from '../images/64.png'
import logo from '../images/footer_logo.png'


//icons
import { FiInstagram } from "react-icons/fi";
import { FaSquareXTwitter } from "react-icons/fa6";
import { ImWhatsapp } from "react-icons/im";
import { PiLinkedinLogoBold } from "react-icons/pi";
import { LuFacebook } from "react-icons/lu";
import { FaThreads } from "react-icons/fa6";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { TbDeviceLandlinePhone } from "react-icons/tb";


import { FaMobileScreen } from "react-icons/fa6";
import { Link } from 'react-router-dom';


const Footer = () => {

    function topPage() {
        window.scroll(0, 0)
    }

    return (
        <>

            <div className='w-full'>

                <div class="bg-[#070707] font-[poppins]  pt-4 md:pt-8">
                    <div class="px-6  w-full  md:w-[85%] mx-auto md:px-4 sm:px-6 text-gray-800 ">


                        <div className='flex justify-center items-center pb-8'>
                            <div class="mt-1 md:mt-2 flex-col  flex gap-x-4 md:gap-x-8 ">


                                <div className='mt-1 md:mt-2 flex-row  flex gap-x-4 md:gap-x-8 '>
                                    <Link to='https://x.com/CF_academy2024?t=50Xz_jo1R8-TMc3gVJnwwQ&s=09' target='_blank' >
                                        <div className='group w-6 h-6 md:w-9 md:h-9 text-white border border-white hover:border  hover:border-gray-300 hover:bg-[#fbfbfb] rounded-md flex justify-center items-center shadow-md drop-shadow-md  cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                            <FaSquareXTwitter className='text-[12px] sm:text-[13px] md:text-[16px] text-white  group-hover:text-gray-900' />
                                        </div>
                                    </Link>

                                    <Link to="https://www.instagram.com/cinema_factory_academy?igsh=MWY1bXRzcXdwdzFh" target='_blank'>

                                        <div className='group w-6 h-6 md:w-9 md:h-9 text-white border border-white hover:border  hover:border-gray-300 hover:bg-[#fbfbfb] rounded-md flex justify-center items-center shadow-md drop-shadow-md  cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                            <FiInstagram className='text-[12px] sm:text-[13px] md:text-[16px] text-white  group-hover:text-gray-900' />
                                        </div>
                                    </Link>

                                    <Link to="https://api.whatsapp.com/send?phone=9884683888" target='_blank' className="">
                                        <div className='group w-6 h-6 md:w-9 md:h-9 text-white border border-white hover:border  hover:border-gray-300 hover:bg-[#fbfbfb] rounded-md flex justify-center items-center shadow-md drop-shadow-md  cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                            <ImWhatsapp className='text-[12px] sm:text-[13px] md:text-[16px] text-white  group-hover:text-gray-900' />
                                        </div>
                                    </Link>

                                    <Link to="https://www.linkedin.com/in/cinema-factory-academy/" target='_blank' className="">
                                        <div className='group w-6 h-6 md:w-9 md:h-9 text-white border border-white hover:border  hover:border-gray-300 hover:bg-[#fbfbfb] rounded-md flex justify-center items-center shadow-md drop-shadow-md  cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                            <PiLinkedinLogoBold className='text-[12px] sm:text-[13px] md:text-[16px] text-white  group-hover:text-gray-900' />
                                        </div>
                                    </Link>

                                    <Link to='https://www.facebook.com/profile.php?id=61559751436051' target='_blank'>

                                        <div className='group w-6 h-6 md:w-9 md:h-9 text-white border border-white hover:border  hover:border-gray-300 hover:bg-[#fbfbfb] rounded-md flex justify-center items-center shadow-md drop-shadow-md cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                            <LuFacebook className='text-[12px] sm:text-[13px] md:text-[16px] text-white  group-hover:text-gray-900' />
                                        </div>
                                    </Link>

                                    <Link to="https://www.threads.net/@cinema_factory_academy" target='_blank'>
                                        <div className='group w-6 h-6 md:w-9 md:h-9 text-white border border-white hover:border  hover:border-gray-300 hover:bg-[#fbfbfb] rounded-md flex justify-center items-center shadow-md drop-shadow-md cursor-pointer hover:shadow-lg hover:drop-shadow-xl hover:animate-bounce'>
                                            <FaThreads className='text-[12px] sm:text-[13px] md:text-[16px] text-white  group-hover:text-gray-900' />
                                        </div>
                                    </Link>
                                </div>


                            </div>
                        </div>


                        {/* <div class="p-3 md:p-4">
                            <div class="text-[14px] sm:text-[15px] md:text-[16px] uppercase text-sky-700 font-bold">Courses</div>
                            <Link class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px] " to="https://direction.cinemafactory.co.in/" target='_blank' >Direction </Link>
                            <Link class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px] " to="https://cinematography.cinemafactory.co.in/" target='_blank' >Cinematography </Link>
                            <Link class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px] " to="https://vfx.cinemafactory.co.in/" target='_blank' >CG & VFX</Link>
                            <Link class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px] " to="https://editing.cinemafactory.co.in/" target='_blank' >Editing</Link>
                        </div> */}

                        {/* <div class="p-3 md:p-4">
                            <div class="text-[14px] sm:text-[15px] md:text-[16px]  uppercase text-sky-700 font-bold">Contact us</div>
                            <h3 className='capitalize my-3 text-[12px] sm:text-[14px] md:text-[14px] font-semibold text-gray-100'>BigBay Cinema Factory Pvt Ltd</h3>

                            <p class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px]" href="/#"> No.271A, 3rd Floor, Maan Sarovar Tower, Scheme Road, Teynampet, Chennai - 600018 India</p>
                            <p class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px]" href="/#">contact@cinemafactory.co.in </p>

                            <p className='text-gray-200 text-[12px] sm:text-[13px] md:text-[14px] my-3 flex items-center gap-x-2 '><MdOutlinePhoneAndroid className='text-[20px] font-bold' /> +91 9884683888</p>
                            <p className='text-gray-200 text-[12px] sm:text-[13px] md:text-[14px] my-3 flex items-center gap-x-2 '><MdOutlinePhoneAndroid className='text-[20px] font-bold' /> +91 9884428688</p>
                            <p className='text-gray-200 text-[12px] sm:text-[13px] md:text-[14px] my-3 flex items-center gap-x-2 '><TbDeviceLandlinePhone className='text-[20px] font-bold' /> +91 44 24815365</p>

                        </div> */}


                        {/* <div class=" p-3 md:p-4">
                            <img src={logo} className=' w-32 sm:w-36 md:w-48 opacity-90 object-cover drop-shadow-lg' alt="" />
                        </div> */}


                        {/* <div class="p-3 md:p-4">
                            <div class="text-[14px] sm:text-[15px] md:text-[16px]  uppercase text-sky-700 font-bold">Support</div>
                            <a class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px]" href="/#">Help Center </a>
                            <Link to='privacy-policy' onClick={topPage}>
                                <a class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px]" href="/#">Privacy Policy </a>
                            </Link>

                            <Link to='terms-conditions' onClick={topPage}>
                                <a class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px]" href="/#">Terms & Conditions</a>
                            </Link>

                            <Link to='cancellation-refund' onClick={topPage}>
                                <a class="text-gray-200 my-3 block  text-[12px] sm:text-[13px]  md:text-[14px]" href="/#">Cancellation & Refund</a>
                            </Link>
                        </div> */}



                    </div>
                </div>


                <div class="bg-[#070707] md:pt-2">
                    <div class="flex flex-col  pb-2 md:pb-5 px-3 m-auto pt-5 border-t text-gray-800 text-sm  w-[80%]  items-center">

                        <div class=" my-2 md:my-5 text-[10px] sm:text-[12px] md:text-[14px] text-gray-100"> © Vishnu Ads and Ventures 2024. All Rights Reserved.</div>

                    </div>
                </div>

            </div>



        </>
    )
}

export default Footer
