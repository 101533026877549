import React, { useState } from 'react'
// import Confetti from 'react-confetti';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Home from './pages/Home'
import AllCourse from './pages/AllCourse'
import Footer from './components/Footer'
import Progressbar from './components/Progressbar'
// import Sidebar from './admin/Sidebar'
// import Admin from './admin/Admin'
// import Terms from './pages/Terms'
// import Privacy from './pages/Privacy'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import About from './pages/About'
import Contact from './pages/Contact'
import Manoj from './components/mentors/Manoj'
import Arvind from './components/mentors/Arvind'
import Direction from './pages/course/Direction'
import Admission from './pages/Admission'
import Cinematography from './pages/course/Cinematography'
import Editing from './pages/course/Editing'
import Acting from './pages/course/Acting'
import Vfx from './pages/course/Vfx'
import MultiForm from './pages/MultiForm'
import Stanley from './components/mentors/Stanley'
import StillPhotography from './pages/course/StillPhotography'


import manoj from './images/banner/entry.jpg'
import mobilemonoj from './images/newMobile.png'
import ban from './images/2.png'

import logo from './images/footer_logo-1.png'
import Madhu from './components/mentors/Madhu'

// import Refund from './pages/Refund';


const App = () => {

  const [showImage, setShowImage] = useState(false);
  // const [showConfetti, setShowConfetti] = useState(false);

  const handleImageClick = () => {
    setShowImage(true);

  };

  // setTimeout(() => {
  //   setShowConfetti(false);
  // }, 10000); // 15000 milliseconds = 15 seconds


  const handleImageOk = () => {
    window.location.href = '/'; // Redirect to home page
  };


  return (


    <>

      {!showImage && (
        <div className='flex justify-center items-center font-[Cardo]'>

          <div className='fixed top-0 -z-20 bg-black/90 w-full'>
            <img src={ban} className='w-full object-cover opacity-50 h-screen md:h-auto ' alt="" />
          </div>


          <div className='w-full z-10'>

            <div className='w-full  fixed top-0 py-3 px-4  md:px-8'>
              <img src={logo} className='object-cover  w-52 md:w-60 ' alt="" />
            </div>


            <div className=''>
              <div className='w-full  h-screen bg-orange-600/55 md:bg-transparent flex flex-col-reverse md:flex-row justify-center items-center'>

                <div className=' relative w-full'>

                  <div className='flex md:hidden'>
                    <img
                      src={mobilemonoj}
                      alt="Placeholder"
                      className="mx-auto w-full  h-screen  drop-shadow-lg object-cover cursor-pointer"
                      onClick={handleImageOk}
                    />
                  </div>

                  <div className='hidden md:flex'>
                    <img
                      src={manoj}
                      alt="Placeholder"
                      className="mx-auto w-full h-screen drop-shadow-lg object-cover cursor-pointer"
                    // onClick={handleImageOk}
                    />
                  </div>


                  <div className=' justify-end absolute w-[82%]  bottom-0 hidden md:flex '>
                    <button
                      onClick={handleImageClick}
                      className="border border-white hover:border-[#ddb100] bg-[#ddb100] text-white px-3 py-2 md:px-6 md:py-2 h-fit rounded m-2 hover:bg-transparent  animate-bounce duration-700 delay-300 text-[14px] md:text-[16px] font-semibold"
                    >
                      Your Flimmaking Journey Begins Now!
                    </button>
                  </div>

                  {/*  ------- mobile buttton -------- */}

                  <div className=' justify-end absolute w-[88%]  top-80 flex md:hidden'>
                    <button
                      onClick={handleImageClick}
                      className="border border-white hover:border-[#ddb100] bg-[#ddb100] text-white px-3 py-2 md:px-6 md:py-3 h-fit rounded m-2 hover:bg-transparent animate-bounce duration-700 delay-300 font-[] text-[14px] md:text-[16px] font-semibold"
                    >
                      Your Flimmaking Journey Begins Now!
                    </button>
                  </div>


                </div>


                {/* <div className='w-full md:w-[40%]   -z-10 -mt-8 md:mt-0  md:-mb-24'>

                  <div className='flex md:hidden'>
                    <img
                      src={image}
                      alt="Placeholder"
                      className="mx-auto w-[90%] md:w-full  drop-shadow-lg object-cover cursor-pointer"
                      onClick={handleImageOk}
                    />
                  </div>

                  <div className='hidden md:flex'>
                    <img
                      src={men}
                      alt="Placeholder"
                      className="mx-auto w-[90%] md:w-full  drop-shadow-lg object-cover cursor-pointer"
                      onClick={handleImageOk}
                    />
                  </div>
                </div> */}


                {/* <div className='w-full md:w-[60%] md:bg-orange-500/55 h-auto md:h-screen mx-auto pb-6 md:pb-0'>

                  <div className='px-2 md:px-20 mt-20 md:mt-20 object-cover'>
                    <img src={name} className='flex  object-cover ' alt="" />
                  </div>

                  <div className='flex justify-center'>
                    <button
                      onClick={handleImageClick}
                      className="border border-gray-400 hover:border-orange-500  text-white px-3 py-2 md:px-6 md:py-3 rounded m-2 hover:bg-orange-500 animate-bounce duration-700 delay-300 font-[] text-[14px] md:text-[16px] font-semibold"
                    >
                      Your Flimmaking Journey Begins Now!
                    </button>
                  </div>

                </div> */}

              </div>

            </div>
          </div>
        </div>

      )}


      {showImage && (
        <>
          <Router className=''>
            <Progressbar />
            <Header />

            <ToastContainer position="top-right" />
            <Routes>
              <Route path='/' element={<Home />} />

              <Route path='about' element={<About />} />
              <Route path='contact' element={<Contact />} />
              <Route path='admission' element={<Admission />} />
              <Route path='apply' element={<MultiForm />} />



              {/* -------- mentors pages --------- */}
              <Route path='manoj_paramahamsa' element={<Manoj />} />
              <Route path='madhu_ambat' element={<Madhu />} />
              <Route path='aravind' element={<Arvind />} />
              <Route path='stanley' element={<Stanley />} />



              {/* -------- courses pages --------- */}

              <Route path='courses' element={<AllCourse />} />
              <Route path='direction' element={<Direction />} />
              <Route path='cinematography' element={<Cinematography />} />
              <Route path='editing' element={<Editing />} />
              <Route path='acting' element={<Acting />} />
              <Route path='vfx' element={<Vfx />} />
              <Route path='still_photography' element={<StillPhotography />} />


              {/* <Route path="/terms-conditions" element={<Terms />} />
               <Route path="/privacy-policy" element={<Privacy />} />
               <Route path="/cancellation-refund" element={<Refund />} /> */}
              {/* <Route path="/admin/*" element={<Admin />} /> */}
            </Routes>

            <Footer />

          </Router >
        </>
      )}
    </>

  )
}


export default App
